import { CHECK_NOTIFICATION_TIME, RESET_NOTIFICATION, UPDATE_NOTIFICATION_TIME } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {}

export default (state=INITIAL_STATE, action) => {   
    switch(action.type){
        case CHECK_NOTIFICATION_TIME:
            return { ...state, ...action.payload }
        case UPDATE_NOTIFICATION_TIME:
            return { ...state, next_notified:action.payload.payload }
        case RESET_NOTIFICATION:
            return {}
        default: return state;
    }
}