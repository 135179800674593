import { FETCH_CERT_IMAGES, RESET } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {data:{},code:''}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_CERT_IMAGES:
            return { ...state, data:_.mapKeys(action.payload, 'image_id'), code:200 }
        case RESET:
            return {}
        default: return state;
    }
}