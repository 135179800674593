// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
// material
import GlobalStyles from './theme/globalStyles';
import { useEffect, useState } from 'react';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
// components
import ScrollToTop from './components/ScrollToTop';
import { removeAlerts } from './actions/alertAction';

// ----------------------------------------------------------------------

function App(props) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(!_.isEmpty(props.alerts.key)){
      setIsOpen(true);
      setTimeout(() => {
        setIsOpen(false);
        props.removeAlerts();
      }, 1500);
    }
  },[props.alerts.key]);

  return ( <>
    <ThemeConfig>
    {
      isOpen && 
        <Snackbar open={isOpen} anchorOrigin={{ vertical:"top", horizontal:"right" }}>
          <Alert severity={props.alerts.status}  variant="filled" color={props.alerts.color}>
            {props.alerts.message}
          </Alert>
        </Snackbar>
    }
      <ScrollToTop />
      <GlobalStyles />
      <Router />
    </ThemeConfig>
    </>
  );
}
const mapStateToProps = state => {
  return {
    alerts: state.alerts,
  }
}
export default connect(mapStateToProps, { removeAlerts })(App);

