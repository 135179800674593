import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import React from 'react'
// material
import {
  Card,
  Table,
  Stack,
  Box,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination
} from '@mui/material';
import { connect } from 'react-redux';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { fetchAllUsers, notifyUser, setUserVerTime, resetUser } from '../actions/userListAction';
import _ from 'lodash';
import { fetchCertificateList } from '../actions/certificateListAction';
import ModalCompoennt from './Portal/ModalCompoennt';
import UserDetails  from './UserDetails';
import { RESET_USER_DETAIL } from "../actions/types";
import { resetState } from '../actions/resetAction';
import { fetchUserDetails } from '../actions/userDetailsAction';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'emailphone', label: 'Email/Phone', alignRight: false },
  { id: 'street', label: 'Street', alignRight: false },
  { id: 'suburb', label: 'Suburb', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'postcode', label: 'Post Code', alignRight: false },
  { id: 'quarantineday', label: 'Quarantine Day', alignRight: false },
  { id: 'action', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

function User(props) {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterStreet, setFilterStreet] = useState('');
  const [filterSuburb, setFilterSuburb] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterPostCode, setFilterPostCode] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [userObj, setUserObj] = useState({});

  const closeButton = () => {
    props.resetState(RESET_USER_DETAIL);
    setOpen(false);
  }

  const handleOpen = (user) => {
    var bodyFormData = new FormData();
    bodyFormData.append('user_id', user.user_id);
    props.fetchUserDetails(bodyFormData);
    setUserObj(user);
    setOpen(true);
  }

  useEffect(() => {
    var bodyFormData = new FormData();
    bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
    props.fetchAllUsers(bodyFormData);
    props.fetchCertificateList(bodyFormData);
  }, []);

  const applyFilter = (array) => {
    const sorted_array= array.sort(function(a,b){
      return new Date(b.date).getTime() - new Date(a.date).getTime() ;
    });
    const stabilizedThis = sorted_array.map((el, index) => ([el, index]));
    if (filterName != "" || filterEmail != "" || filterStreet != "" || filterSuburb != "" || filterState != "" || filterPostCode != "") {
      return filter(sorted_array, (_user) => _user.name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 
      && _user.email.toLowerCase().indexOf(filterEmail.toLowerCase()) !== -1 
      && _user.address.toLowerCase().indexOf(filterStreet.toLowerCase()) !== -1
      && _user.address.toLowerCase().indexOf(filterSuburb.toLowerCase()) !== -1
      && _user.address.toLowerCase().indexOf(filterState.toLowerCase()) !== -1
      && _user.address.toLowerCase().indexOf(filterPostCode.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterByEmail = (event) => {
    setFilterEmail(event.target.value);
  };

  const handleFilterByStreet = (event) => {
    setFilterStreet(event.target.value);
  };

  const handleFilterBySuburb = (event) => {
    setFilterSuburb(event.target.value);
  };

  const handleFilterByState = (event) => {
    setFilterState(event.target.value);
  };

  const handleFilterByPostCode = (event) => {
    setFilterPostCode(event.target.value);
  };

  const showQuarantineDays = (u) => {
    const date = new Date(u.date);
    const newdate = new Date();
    var msDiff = Math.abs(newdate.getTime() - date.getTime()); 
    var rdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    if(rdays == u.quarantine_days) {
      return `${rdays} (last day)`+"/"+u.quarantine_days;
    } else if(rdays > u.quarantine_days) {
      return u.quarantine_days+"/"+u.quarantine_days+"(completed)";
    } else {
      return (rdays+1)+"/"+u.quarantine_days;
    }
  }
  const filteredUsers = applyFilter(props.users);

  const isUserNotFound = filteredUsers.length === 0;

  const showStreet = (user) => {
    const street = user.address;
    const str = street.split(' ');
    const final = str[0]+" "+str[1]+" "+str[2];
    const finalStreet = final.replace(','," ");
    if(finalStreet == "undefined undefined undefined") {
      return " ";
    } else {
      return finalStreet;
    }
  }

  const showSuburb = (user) => {
    const street = user.address;
    const str = street.split(' ');
    return str[3] || " ";
  }

  const showState = (user) => {
    const street = user.address;
    const str = street.split(' ');
    return str[4] || " ";
  }

  const showPostCode = (user) => {
    const street = user.address;
    const str = street.split(' ');
    const postCode = str[5]+" ";
    const finalPostCode = postCode.replace(','," ");
    if(finalPostCode == "undefined ") {
      return " ";
    } else {
      return finalPostCode;
    }
  }

  return (
    <Page title="People In Isolation">
      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            People In Isolation
          </Typography>
        </Stack>

        <Card>
          {/* <Box sx={{ display:"flex" }}>
          <UserListToolbar
              numSelected={selected.length}
              filter={filterName}
              onFilter={handleFilterByName}
              filterPlaceHolder="Filter by Name"
            />
            <UserListToolbar
              numSelected={selected.length}
              filter={filterEmail}
              onFilter={handleFilterByEmail}
              filterPlaceHolder= "Filter by Email or Phone"
            />
            <UserListToolbar
              numSelected={selected.length}
              filter={filterAddress}
              onFilter={handleFilterByAddress}
              filterPlaceHolder= "Filter by Street, Subrub, State, Postcode"
            />
          </Box> */}

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={props.users.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableRow>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterName}
                      onFilter={handleFilterByName}
                      filterPlaceHolder= "Name..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterEmail}
                      onFilter={handleFilterByEmail}
                      filterPlaceHolder= "Email/Phone..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterStreet}
                      onFilter={handleFilterByStreet}
                      filterPlaceHolder= "Street..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterSuburb}
                      onFilter={handleFilterBySuburb}
                      filterPlaceHolder= "Suburb..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterState}
                      onFilter={handleFilterByState}
                      filterPlaceHolder= "State..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterPostCode}
                      onFilter={handleFilterByPostCode}
                      filterPlaceHolder= "Postcode..."
                    />
                  </TableCell>
                  {/* <TableCell align="left" style={{padding:"0px"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterAddress}
                      onFilter={handleFilterByAddress}
                      filterPlaceHolder= "Date..."
                    />
                  </TableCell> */}
                </TableRow>
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => {
                      const isItemSelected = selected.indexOf(user.id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={user.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          {/* <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, user.id)}
                            />
                          </TableCell> */}
                          <TableCell align="left" style={{padding:"12px"}}>{user.id}</TableCell>
                          <TableCell align="left" style={{padding:"12px"}}>{user.name}</TableCell>
                          <TableCell align="left" style={{padding:"12px"}}>{user.email}</TableCell>
                          <TableCell alig n="left" style={{padding:"12px"}}>{showStreet(user)}</TableCell>
                          <TableCell align="left" style={{padding:"12px"}}>{showSuburb(user)}</TableCell>
                          <TableCell align="left" style={{padding:"12px"}}>{showState(user)}</TableCell>
                          <TableCell align="left" style={{padding:"12px"}}>{showPostCode(user)}</TableCell>
                          <TableCell align="left" style={{padding:"12px"}}>{showQuarantineDays(user)}</TableCell>
                          <TableCell align="left" style={{padding:"12px"}}>
                            <UserMoreMenu userObject={user} isTrue={true} handleClick={() => handleOpen(user)} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[50, 100, 150]}
            component="div"
            count={props.users.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      {
        open && 
          <ModalCompoennt open={open} onClose={closeButton} title="User Details" user={userObj} width="lg">
            <UserDetails user={userObj} />
          </ModalCompoennt>
      }
    </Page>
  );
}

const mapStateToProps = state => {
  return {
    users:Object.values(state.users),
    certificates:Object.values(state.certificates)
  }
}

export default connect(mapStateToProps, { fetchCertificateList, fetchAllUsers, notifyUser, setUserVerTime, resetState, fetchUserDetails })(User);