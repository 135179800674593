import axios from 'axios';
import _ from 'lodash';
import shortId from 'shortid';
import { GET_CONFIG_INFO, UPDATE_NOTIFICATION_TIMEOUT, UPDATE_LOCATION_RADIUS, ADD_ALERT  } from './types';

export const fetchConfigInfo= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/fetch_config_info",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:GET_CONFIG_INFO, 
            payload:response.data
        });
    }).catch(error => {
    });
}

export const updateNotificationTimeOut= (bodyFormData, timeOutValue) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/update_config_verification_timeout",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        if(response.data.code == '1') {
            dispatch({
                type: UPDATE_NOTIFICATION_TIMEOUT,
                payload: {
                    payload: timeOutValue
                }
            });
            dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'success',
                    color:'info',
                    message: response.data.desc,
                    errors: {}
                }
            });
        }
        
    }).catch(error => {
    });
}

export const updateLocationRadius= (bodyFormData, radiusValue) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/update_config_verification_radius",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        if(response.data.code == '1') {
            dispatch({
                type: UPDATE_LOCATION_RADIUS,
                payload: {
                    payload: radiusValue
                }
            });
            dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'success',
                    color:'info',
                    message: response.data.desc,
                    errors: {}
                }
            });
        }
        
    }).catch(error => {
    });
}