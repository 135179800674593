import React from 'react';
// material
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import {
    Box,
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    OutlinedInput,
    InputAdornment
} from '@mui/material';

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width:"100%",
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: "100%", boxShadow: theme.customShadows.z8 },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`,
    }
}));

export function TextSearchFilter({
    column: { filterValue, setFilter }
    }) {
        console.warn('in text filed search filter')
    return (
        <SearchStyle
            sx={{paddingLeft:"5px",
            width:"100%",
            '@media (min-width: 1300px)': {
                fontSize:"12px",
            }
            }}
            size="small"
            value={filterValue || ""}
            onChange={(e) => {
                setFilter(e.target.value || undefined);
            }}
            placeholder='search...'
            startAdornment={
                <InputAdornment position="start">
                    <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                </InputAdornment>
            }
        />
    );
}
