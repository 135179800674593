import { 
    FETCH_TERMS,
    UPDATE_IRHERE_TERMS,
    UPDATE_BVDT_TERMS,
    UPDATE_AVDT_TERMS,
    ADD_ALERT,
  } from './types';
  import shortId from "shortid";
  import axios from 'axios';

  export const fetchTerms= () => async dispatch => {
        await axios({
            method: "post",
            url: `https://irhere.com.au/api/get_terms`,
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
        }).then(response => {
            dispatch({
                type:FETCH_TERMS, 
                payload:response.data
            });
        }).catch(error => {
            console.log(error)
        });
}

export const updateIrhereTerms = (term,values) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    axios
        .post("https://irhere.com.au/api/update_terms", values, config)
        .then(function (response) {
            if(response.data.code == "1") {
                dispatch({
                    type: ADD_ALERT,
                    payload: {
                        key: shortId.generate(),
                        status: 'success',
                        color:'info',
                        message: response.data.desc,
                        errors: {}
                    }
                });
                dispatch({
                    type:UPDATE_IRHERE_TERMS, 
                    payload:term
                });
            } else if(response.data.code == "0") {
                dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'error',
                    color:'error',
                    message: response.data.desc,
                    errors: {}
                },
            });
        }
    })
    .catch(function (error) {
    });
}

export const updateBvdTerms = (term,values) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    axios
        .post("https://irhere.com.au/api/update_terms_bvdt", values, config)
        .then(function (response) {
            if(response.data.code == "1") {
                dispatch({
                    type: ADD_ALERT,
                    payload: {
                        key: shortId.generate(),
                        status: 'success',
                        color:'info',
                        message: response.data.desc,
                        errors: {}
                    }
                });
                dispatch({
                    type:UPDATE_BVDT_TERMS, 
                    payload:term
                });
            } else if(response.data.code == "0") {
                dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'error',
                    color:'error',
                    message: response.data.desc,
                    errors: {}
                },
            });
        }
    })
    .catch(function (error) {
    });
}

export const updateAvdTerms = (term,values) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    axios
        .post("https://irhere.com.au/api/update_terms_avdt", values, config)
        .then(function (response) {
            if(response.data.code == "1") {
                dispatch({
                    type: ADD_ALERT,
                    payload: {
                        key: shortId.generate(),
                        status: 'success',
                        color:'info',
                        message: response.data.desc,
                        errors: {}
                    }
                });
                dispatch({
                    type:UPDATE_AVDT_TERMS, 
                    payload:term
                });
            } else if(response.data.code == "0") {
                dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'error',
                    color:'error',
                    message: response.data.desc,
                    errors: {}
                },
            });
        }
    })
    .catch(function (error) {
    });
}