import React, { Component } from 'react';
import { useState, useEffect } from 'react';
import {
    MenuItem,
    Grid,
    Box,
    Card,
    TextField,
    Table,
    TableRow,
    TableBody,
    TableCell,
    Stack,
    Typography
} from '@mui/material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { deleteAdmin } from '../../actions/adminAction';


const useStyles = makeStyles({
    root: {
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    card: {
        maxWidth: "100%",
        width:"auto",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        padding:"10px",
        textAlign:"left"
    }
});

function DeleteAdmin(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, [props.alerts]);

    const handleSubmit = () => {
        var bodyFormData = new FormData();
        bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
        bodyFormData.append('user_id', props.admin.id);
        props.deleteAdmin(bodyFormData, props.admin.id);
        props.onDismis();
    }

    return (
        <Grid
            container
            className={classes.root}
            spacing={0}
            alignItems="center"
            justify="center"
        >
            <Card className={classes.card}>
                <Stack sx={{fontSize:"19px", marginBottom:"70px", paddingTop:"10px"}}>
                    Are you sure you want to delete?
                </Stack>
                <LoadingButton
                    size="medium"
                    type="submit"
                    variant="contained"
                    loading={isLoading}
                    sx={{backgroundColor: 'red',float:"right", marginTop:"60px"}}
                    onClick={handleSubmit}
                >
                    Delete
                </LoadingButton>
            </Card>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        alerts: state.alerts
    }
}

export default connect(mapStateToProps, { deleteAdmin })(DeleteAdmin);

