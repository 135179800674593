import { GET_CONFIG_INFO, UPDATE_NOTIFICATION_TIMEOUT, UPDATE_LOCATION_RADIUS, RESET_NOTIFICATION } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case GET_CONFIG_INFO:
            return { ...state, ...action.payload }
        case UPDATE_NOTIFICATION_TIMEOUT:
            return { ...state, verification_timeout:action.payload.payload.verification_timeout }
        case UPDATE_LOCATION_RADIUS: 
            return { ...state, verification_radius: action.payload.payload.verification_radius  }
        case RESET_NOTIFICATION:
            return {}
        default: return state;
    }
}