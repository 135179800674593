import axios from 'axios';
import _ from 'lodash';
import { FETCH_CERT_IMAGES } from "../actions/types";

export const fetchCertImages= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/get_images_gen",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:FETCH_CERT_IMAGES, 
            payload:response.data
        });
    }).catch(error => {
    });
}
