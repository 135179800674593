import { FETCH_CERTIFICATE_LIST, SEARCH_VALIDATION } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {data: {},paginate:{}}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_CERTIFICATE_LIST:
            return {...state, data:_.mapKeys(action.payload.Data, 'id'), paginate:action.payload.Page}
        case SEARCH_VALIDATION:
            return {...state, data:_.mapKeys(action.payload.Data, 'id'), paginate:action.payload.Page}
        default: return state;
    }
}