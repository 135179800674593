//Users
export const NOTIFY_USER = 'NOTIFY_USER';
export const FETCH_ALL_USERS = 'FETCH_ALL_USERS';
export const FETCH_CERTIFICATE_LIST = 'FETCH_CERTIFICATE_LIST';
export const ADD_ALERT = "ADD_ALERT";
export const ALL_ALERTS = "ALL_ALERTS";
export const DELETE_ALERTS = "DELETE_ALERTS";
export const SIGN_IN = "SIGN_IN";
export const LOG_OUT = "LOG_OUT";
export const USER_VERIFICATION_DETAIL = "USER_VERIFICATION_DETAIL";
export const VERIFICATION_DETAILS = "VERIFICATION_DETAILS";
export const RESET_USER_DETAIL = "RESET_USER_DETAIL";
export const RESET = "RESET";
export const VIEW_CERT = "VIEW_CERT";
export const CHECK_NOTIFICATION_TIME = "CHECK_NOTIFICATION_TIME";
export const UPDATE_NOTIFICATION_TIME = "UPDATE_NOTIFICATION_TIME";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const FETCH_ALL_ADMINS = "FETCH_ALL_ADMINS";
export const UPDATE_ADMIN_DETAILS = "UPDATE_ADMIN_DETAILS";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const GET_CONFIG_INFO = "GET_CONFIG_INFO";
export const UPDATE_NOTIFICATION_TIMEOUT = "UPDATE_NOTIFICATION_TIMEOUT";
export const UPDATE_LOCATION_RADIUS = "UPDATE_LOCATION_RADIUS";
export const VIEW_GENERAL_CERT = "VIEW_GENERAL_CERT";
export const FETCH_CERT_IMAGES = "FETCH_CERT_IMAGES";
// TRANSACTION
export const FETCH_ALL_TRANSACTIONS = 'FETCH_ALL_TRANSACTIONS';

// Vouchers
export const FETCH_ALL_VOUCHERS = 'FETCH_ALL_VOUCHERS';
export const CREATE_VOUCHER = 'CREATE_VOUCHER';

// Terms
export const FETCH_TERMS = 'FETCH_TERMS';
export const UPDATE_IRHERE_TERMS = 'UPDATE_IRHERE_TERMS';
export const SEARCH_VALIDATION = 'SEARCH_VALIDATION';
export const UPDATE_BVDT_TERMS = 'UPDATE_BVDT_TERMS';
export const UPDATE_AVDT_TERMS = 'UPDATE_AVDT_TERMS';
// User 
export const SEARCH_USER = 'SEARCH_USER';
