import React, { Component } from 'react';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import {
    MenuItem,
    Grid,
    Box,
    Card,
    TextField,
    Table,
    TableRow,
    TableBody,
    TableCell,
} from '@mui/material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { updateAdminDeatils } from '../../actions/adminAction';


const useStyles = makeStyles({
    root: {
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    card: {
        maxWidth: "100%",
        width:"auto",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        padding:"10px",
        textAlign:"left"
    }
});


function UpdateAdmin(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, [props.alerts]);

    const AddSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
    });

    const formik = useFormik({
        initialValues: {
            name:props.admin.name,
        },
    validationSchema: AddSchema,
        onSubmit: (values, {resetForm}) => {
            resetForm();
            const valuesForReducer = {
                name: values.name != '' ? values.name : props.admin.name,
                email: props.admin.email,
                id: props.admin.id,
            };
            const name = values.name != '' ? values.name : props.admin.name;
            var bodyFormData = new FormData();
            bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
            bodyFormData.append('name', name);
            bodyFormData.append('user_id', props.admin.id);
            props.updateAdminDeatils(bodyFormData, valuesForReducer);
            props.onDismis();
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <Grid
            container
            className={classes.root}
            spacing={0}
            alignItems="center"
            justify="center"
        >
            <Card className={classes.card}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            type="text"
                            label="Name*"
                            {...getFieldProps('name')}
                            error={Boolean(touched.name && errors.name)}
                            helperText={touched.name && errors.name}
                        />
                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            loading={isLoading}
                            sx={{backgroundColor: '#5C81E4',float:"right", marginTop:"30px"}}
                            onClick={handleSubmit}
                        >
                            Update
                        </LoadingButton>
                    </Form>
                </FormikProvider>
            </Card>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        alerts: state.alerts
    }
}

export default connect(mapStateToProps, { updateAdminDeatils })(UpdateAdmin);

