import React from 'react'
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button
} from '@mui/material';
import { connect } from 'react-redux';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu, UserDatePicker } from '../components/_dashboard/user';
import { fetchAllUsers, notifyUser, setUserVerTime, resetUser } from '../actions/userListAction';
import { fetchCertificateList, searchValidation } from '../actions/certificateListAction';
import { fetchVerificationDetails } from '../actions/verificationDetailsAction';
import _ from 'lodash';
import { resetState } from '../actions/resetAction';
import { RESET } from "../actions/types";
import ModalCompoennt from './Portal/ModalCompoennt';
import VerificationDetails  from './VerificationDetails';
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Restore';

const TABLE_HEAD = [
  { id: 'vid', label: 'VID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'emailphone', label: 'Email/Phone', alignRight: false },
  // { id: 'street', label: 'Street', alignRight: false },
  // { id: 'subrub', label: 'Suburb', alignRight: false },
  // { id: 'state', label: 'State', alignRight: false },
  // { id: 'postcode', label: 'Post Code', alignRight: false },
  { id: 'date', label: 'Date', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'actions', label: 'Actions' },
  { id: '', label: '' },
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  height:"90%",
  bgcolor: 'background.paper',
  boxShadow: 50,
  border:"none"
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
  },
  '&.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root':{
    padding:0
  }
}));

function DashboardApp(props) {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterVid, setFilterVid] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  // const [filterStreet, setFilterStreet] = useState('');
  // const [filterSuburb, setFilterSuburb] = useState('');
  // const [filterState, setFilterState] = useState('');
  // const [filterPostCode, setFilterPostCode] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [open, setOpen] = useState(false);
  const [userObj, setUserObj] = useState({});

  useEffect(() => {
    var bodyFormData = new FormData();
    bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
    props.fetchCertificateList(bodyFormData,page);
  }, []);

  useEffect(() => {
    if(!_.isEmpty(props.certificates.paginate)) {
      setTotalPages(props.certificates.paginate.TotalPages);
    }
  },[props.certificates.paginate])

  const showStreet = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      const final = str[0]+" "+str[1]+" "+str[2];
      const finalStreet = final.replace(','," ");
      if(finalStreet == "undefined undefined undefined") {
        return " ";
      } else {
        return finalStreet;
      }
    } else {
      return "-";
    }
  }

  const showSuburb = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      return str[3] || " ";
    } else {
      return "-";
    }
  }

  const showState = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      return str[4] || " ";
    } else {
      return "-"
    }
  }

  const showPostCode = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      const postCode = str[5]+" ";
      const finalPostCode = postCode.replace(','," ");
      if(finalPostCode == "undefined ") {
        return " ";
      } else {
        return finalPostCode;
      }
    } else {
      return "-";
    }
  }

  const renderStatus = (u) => {
    const status = u.status;
    const true_status = u.true_status;
    if(status.toLowerCase() == "true" && true_status.toLowerCase() == "true") {
        return <>
          <Label
            variant="ghost"
            color={'success'}
          >
            {sentenceCase("verified")}
          </Label>
        </>
    } 
    if(status.toLowerCase() == "true" && true_status.toLowerCase() == "false") {
        return <>
          <Label
            variant="ghost"
            color={'error'}
          >
            {sentenceCase("unverified")}
          </Label>
        </>
    } 
    if(status.toLowerCase() == "false" && true_status.toLowerCase() == "true") {
        return <>
          <Label
            variant="ghost"
            color={'warning'}
          >
            {sentenceCase("pending")}
          </Label>
        </>
    } 
    if(status.toLowerCase() == "false" && true_status.toLowerCase() == "false") {
        return <>
          <Label
            variant="ghost"
            color={'error'}
          >
            {sentenceCase("unverified")}
          </Label>
        </>
    }
  }

  const handleFilterByVid = (event) => {
    setFilterVid(event.target.value);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterByEmail = (event) => {
    setFilterEmail(event.target.value);
  };

  // const handleFilterByStreet = (event) => {
  //   setFilterStreet(event.target.value);
  // };

  // const handleFilterBySuburb = (event) => {
  //   setFilterSuburb(event.target.value);
  // };

  // const handleFilterByState = (event) => {
  //   setFilterState(event.target.value);
  // };

  // const handleFilterByPostCode = (event) => {
  //   setFilterPostCode(event.target.value);
  // };

  const handleFilterByStartDate = (event) => {
    setFilterStartDate(event);
  };

  const handleFilterByEndDate = (event) => {
    setFilterEndDate(event);
  };

  const renderCardBody = () => {
    if(!_.isEmpty(props.certificates.data)) {
      let data = Object.values(props.certificates.data);
      return data.map((user) => {
        return <TableRow>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{user.ver_id}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{user.name}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{user.email}</TableCell>
          {/* <TableCell align="left" style={{padding:"10px", width:"auto"}}>{showStreet(user)}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{showSuburb(user)}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{showState(user)}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{showPostCode(user)}</TableCell> */}
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{moment(user.date).format("D MMM, YYYY h:mm A")}</TableCell>
          <TableCell align="left" style={{padding:"10px", width:"auto"}}>{renderStatus(user)}</TableCell>
          {/* <TableCell align="left" colSpan={2} style={{padding:"10px", width:"auto"}}>
            <UserMoreMenu userObject={user} isTrue={false} handleClick={() => handleOpen(user)}/>
          </TableCell> */}
        </TableRow>
      })
    } else {
      return <>
        <TableRow>
          <TableCell colSpan={10}>
            No Validation Found
          </TableCell>
        </TableRow>
      </>
    }
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    var bodyFormData = new FormData();
    bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
    props.fetchCertificateList(bodyFormData,newPage);
  };

  const searchRecord = () => {
    if(filterName != '' || filterEmail != '' || filterStartDate != null || filterEndDate != null || filterVid != '' ) {
      const values = {
        name: filterName,
        email: filterEmail,
        start_date: filterStartDate != null ? moment(new Date(filterStartDate)).format("YYYY-MM-DD") : '',
        end_date: filterEndDate!= null ? moment(new Date(filterEndDate)).format("YYYY-MM-DD") : ''
      };
      props.searchValidation(filterVid);
      // console.log(values);
    } else {
      console.log('please enter record')
    }
  }

  const handleReset = () => {
      setFilterName('');
      setFilterEmail('');
      setFilterVid('');
      // setFilterStreet('');
      // setFilterState('');
      // setFilterSuburb('');
      // setFilterPostCode('');
      setFilterStartDate(null);
      setFilterEndDate(null);
      var bodyFormData = new FormData();
      bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
      props.fetchCertificateList(bodyFormData,page);
  }

  return (
    <Page title="List of Verifications">
      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            List of Validations
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={props.certificates.length}
                  numSelected={selected.length}
                />
                <TableRow>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterVid}
                      onFilter={handleFilterByVid}
                      filterPlaceHolder= "VID..."
                      showUpdateButton={false}
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterName}
                      onFilter={handleFilterByName}
                      filterPlaceHolder= "Name..."
                      showUpdateButton={false}
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterEmail}
                      onFilter={handleFilterByEmail}
                      filterPlaceHolder= "Email/Phone..."
                    />
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Button variant="contained" onClick={searchRecord} sx={{backgroundColor:"rgb(92, 129, 228)"}} startIcon={<SearchIcon />}>Search</Button>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Button variant="contained" onClick={handleReset} sx={{backgroundColor:"red"}} startIcon={<ResetIcon />} >Reset</Button>
                  </TableCell>
                  {/* <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterStartDate}
                      onFilter={handleFilterByStartDate}
                      filterPlaceHolder= "From..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterEndDate}
                      onFilter={handleFilterByEndDate}
                      filterPlaceHolder= "To..."
                    />
                  </TableCell> */}
                  {/* <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={3}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterStreet}
                      onFilter={handleFilterByStreet}
                      filterPlaceHolder= "Street..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterSuburb}
                      onFilter={handleFilterBySuburb}
                      filterPlaceHolder= "Suburb..."
                    />
                  </TableCell> */}
                </TableRow>
                {/* <TableRow>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterState}
                      onFilter={handleFilterByState}
                      filterPlaceHolder= "State..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={2}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterPostCode}
                      onFilter={handleFilterByPostCode}
                      filterPlaceHolder= "Postcode..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterStartDate}
                      onFilter={handleFilterByStartDate}
                      filterPlaceHolder= "From..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={2}>
                    <UserDatePicker
                      numSelected={selected.length}
                      filter={filterEndDate}
                      onFilter={handleFilterByEndDate}
                      filterPlaceHolder= "To..."
                    />
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Button variant="contained" onClick={searchRecord} sx={{backgroundColor:"rgb(92, 129, 228)"}} startIcon={<SearchIcon />}>Search</Button>
                  </TableCell>
                  <TableCell colSpan={1}>
                    <Button variant="contained" onClick={handleReset} sx={{backgroundColor:"red"}} startIcon={<ResetIcon />} >Reset</Button>
                  </TableCell>
                </TableRow> */}
                <TableBody>
                  {renderCardBody()}
                </TableBody>
              </Table>
              <Pagination sx={{float:"right",marginTop:"10px",marginBottom:"10px"}} page={page} count={totalPages} variant="outlined" color="primary" onChange={handlePageChange} />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  )
}
const mapStateToProps = state => {
  return {
    users:Object.values(state.users),
    certificates:state.certificates,
    verDetails: state.verificationDetails
  }
}
export default connect(mapStateToProps, { searchValidation, fetchAllUsers, notifyUser, fetchCertificateList, setUserVerTime, resetUser, fetchVerificationDetails, resetState })(DashboardApp);
