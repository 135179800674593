import axios from 'axios';
import _ from 'lodash';
import { VIEW_CERT, VIEW_GENERAL_CERT } from "../actions/types";

export const viewCert= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/get_certificate_from_id",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:VIEW_CERT, 
            payload:response.data
        });
    }).catch(error => {
    });
}

export const viewGeneralCert= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/get_certificategen_from_id",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:VIEW_CERT, 
            payload:response.data
        });
    }).catch(error => {
    });
}

export const fetchCertImages= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/get_images_gen",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:VIEW_CERT, 
            payload:response.data
        });
    }).catch(error => {
    });
}
