import React, { Component } from 'react'
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import * as Yup from 'yup';
import {
    MenuItem,
    Grid,
    Box,
    Card,
    TextField,
    Table,
    TableRow,
    TableBody,
    TableCell,
} from '@mui/material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment';
import { updateNotificationTimeOut, updateLocationRadius } from 'src/actions/settingsAction';
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';


const useStyles = makeStyles({
    root: {
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    card: {
        maxWidth: "100%",
        width:"auto",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        padding:"10px",
        textAlign:"left"
    }
});


function UpdateSettings(props) {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(false);
    }, [props.alerts]);

    const AddSchema = Yup.object().shape({
        verification_timeout: Yup.string().required('verification timeout is required'),
        verification_radius: Yup.string().required('verification radius is required'),
    });

    const formik = useFormik({
        initialValues: {
            verification_timeout:props.setting.verification_timeout,
            verification_radius:props.setting.verification_radius,
        },
        validationSchema: AddSchema,
        onSubmit: (values, {resetForm}) => {
            resetForm();
            const timeOutValue = {
                verification_timeout: values.verification_timeout != '' ? values.verification_timeout : props.setting.verification_timeout,
            };
            const radiusValue = {
                verification_radius: values.verification_radius != '' ? values.verification_radius : props.setting.verification_radius,
            };
            const verification_timeout = values.verification_timeout != '' ? values.verification_timeout : props.setting.verification_timeout;
            const verification_radius = values.verification_radius != '' ? values.verification_radius : props.setting.verification_radius;
            var bodyFormData = new FormData();
            bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
            bodyFormData.append('verification_timeout', verification_timeout);
            var bodyFormData2 = new FormData();
            bodyFormData2.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
            bodyFormData2.append('verification_radius', verification_radius);
            props.updateNotificationTimeOut(bodyFormData, timeOutValue);
            props.updateLocationRadius(bodyFormData2, radiusValue);
            props.onDismis();
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    return (
        <Grid
            container
            className={classes.root}
            spacing={0}
            alignItems="center"
            justify="center"
        >
            <Card className={classes.card}>
                <FormikProvider value={formik}>
                    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                        <TextField
                            fullWidth
                            sx={{margin:"20px 0px 30px 0px"}}
                            type="text"
                            label="Verification Timeout*"
                            {...getFieldProps('verification_timeout')}
                            error={Boolean(touched.verification_timeout && errors.verification_timeout)}
                            helperText={touched.verification_timeout && errors.verification_timeout}
                        />
                        <TextField
                            fullWidth
                            type="text"
                            label="Verification Radius*"
                            {...getFieldProps('verification_radius')}
                            error={Boolean(touched.verification_radius && errors.verification_radius)}
                            helperText={touched.verification_radius && errors.verification_radius}
                        />
                        <LoadingButton
                            size="medium"
                            type="submit"
                            variant="contained"
                            loading={isLoading}
                            sx={{backgroundColor: '#5C81E4',float:"right", marginTop:"30px"}}
                            onClick={handleSubmit}
                        >
                            Update
                        </LoadingButton>
                    </Form>
                </FormikProvider>
            </Card>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        alerts: state.alerts
    }
}

export default connect(mapStateToProps, { updateNotificationTimeOut, updateLocationRadius })(UpdateSettings);

