import { filter } from 'lodash';
import { Icon } from '@iconify/react';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import React from 'react'
// material
import {
  Card,
  Table,
  Stack,
  Box,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer
} from '@mui/material';
import { connect } from 'react-redux';
// components
import Page from '../../components/Page';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user/index';
import { fetchAllUsers, notifyUser, setUserVerTime, resetUser, searchUser } from '../../actions/userListAction';
import _ from 'lodash';
import { fetchCertificateList } from '../../actions/certificateListAction';
import ModalCompoennt from '../Portal/ModalCompoennt';
import UserDetails  from '../UserDetails';
import { RESET_USER_DETAIL } from "../../actions/types";
import { resetState } from '../../actions/resetAction';
import { fetchUserDetails } from '../../actions/userDetailsAction';
import UpdateUser from './UpdateUser';
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Restore';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'pid', label: 'PID' },
  { id: 'name', label: 'Name' },
  { id: 'emailphone', label: 'Email/Phone' },
  // { id: 'street', label: 'Street' },
  // { id: 'suburb', label: 'Suburb' },
  // { id: 'state', label: 'State' },
  // { id: 'postcode', label: 'Post Code' },
  { id: 'action', label: 'Actions' },
];

// ----------------------------------------------------------------------

function UsersList(props) {
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [filterName, setFilterName] = useState('');
  const [filterPid, setFilterPid] = useState('');
  const [filterEmail, setFilterEmail] = useState('');
  const [filterStreet, setFilterStreet] = useState('');
  const [filterSuburb, setFilterSuburb] = useState('');
  const [filterState, setFilterState] = useState('');
  const [filterPostCode, setFilterPostCode] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [open, setOpen] = useState(false);
  const [userObj, setUserObj] = useState({});
  const [totalPages, setTotalPages] = useState(0);

  const closeButton = () => {
    props.resetState(RESET_USER_DETAIL);
    setOpen(false);
  }

  useEffect(() => {
    var bodyFormData = new FormData();
    bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
    props.fetchAllUsers(bodyFormData);
    // props.fetchCertificateList(bodyFormData);
  }, []);

  useEffect(() => {
    if(!_.isEmpty(props.users.paginate)) {
      setTotalPages(props.users.paginate.TotalPages);
    }
  },[props.users.paginate]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props.users.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const searchRecord = () => {
    if(filterName != '' || filterEmail != '' || filterPid != '') {
      const values = {
        name: filterName,
        email: filterEmail,
      };
      props.searchUser(filterPid);
      console.log(values);
    } else {
      console.log('insert record');
    }
  }

  const handleReset = () => {
      setFilterName('');
      setFilterPid('');
      setFilterEmail('');
      setFilterStreet('');
      setFilterState('');
      setFilterSuburb('');
      setFilterPostCode('');
      var bodyFormData = new FormData();
      bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
      props.fetchAllUsers(bodyFormData);
  }

  const handleFilterByPid = () => {
    setFilterPid(event.target.value);
  }

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFilterByEmail = (event) => {
    setFilterEmail(event.target.value);
  };

  // const handleFilterByStreet = (event) => {
  //   setFilterStreet(event.target.value);
  // };

  // const handleFilterBySuburb = (event) => {
  //   setFilterSuburb(event.target.value);
  // };

  // const handleFilterByState = (event) => {
  //   setFilterState(event.target.value);
  // };

  // const handleFilterByPostCode = (event) => {
  //   setFilterPostCode(event.target.value);
  // };

  const renderCardBody = () => {
    if(!_.isEmpty(props.users.data)) {
      let data = Object.values(props.users.data);
      return data.map((user) => {
        return <TableRow key={user.id}>
          <TableCell align="left" style={{padding:"12px"}}>{user.pid}</TableCell>
          <TableCell align="left" style={{padding:"12px"}}>{user.name}</TableCell>
          <TableCell align="left" style={{padding:"12px"}}>{user.email}</TableCell>
          <TableCell align="left" style={{padding:"12px"}}>{user.phone_no}</TableCell>
          {/* <TableCell align="left" style={{padding:"12px"}}>{showStreet(user)}</TableCell>
          <TableCell align="left" style={{padding:"12px"}}>{showSuburb(user)}</TableCell>
          <TableCell align="left" style={{padding:"12px"}}>{showState(user)}</TableCell>
          <TableCell align="left" style={{padding:"12px"}}>{showPostCode(user)}</TableCell> */}
          {/* <TableCell align="left" style={{padding:"12px"}} colSpan={2}>
            <UserMoreMenu userObject={user} isTrue={true} handleUpdateUserClick={() => handleUpdateUserClick(user)} />
          </TableCell> */}
        </TableRow>
      })
    } else {
      return <>
        <TableRow>
          <TableCell colSpan={9}>No User Found</TableCell>
        </TableRow>
      </>
    }
  }

  const showStreet = (user) => {
    const street = user.address;
    const str = street.split(' ');
    const final = str[0]+" "+str[1]+" "+str[2];
    const finalStreet = final.replace(','," ");
    if(finalStreet == "undefined undefined undefined") {
      return " ";
    } else {
      return finalStreet;
    }
  }

  const showSuburb = (user) => {
    const street = user.address;
    const str = street.split(' ');
    return str[3] || " ";
  }

  const showState = (user) => {
    const street = user.address;
    const str = street.split(' ');
    return str[4] || " ";
  }

  const showPostCode = (user) => {
    const street = user.address;
    const str = street.split(' ');
    const postCode = str[5]+" ";
    const finalPostCode = postCode.replace(','," ");
    if(finalPostCode == "undefined ") {
      return " ";
    } else {
      return finalPostCode;
    }
  }

  const handleUpdateUserClick = () => {
    setOpen(true);
  }

  const handlePageChange = (event,newPage) => {
    var bodyFormData = new FormData();
    bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
    setPage(newPage);
    props.fetchAllUsers(bodyFormData,newPage);
  }

  return (
    <Page title="Users List">
      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Users List
          </Typography>
        </Stack>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  headLabel={TABLE_HEAD}
                  rowCount={props.users.length}
                  numSelected={selected.length}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableRow>
                  <TableCell align="left" style={{padding:"0px", width:"auto"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterPid}
                      onFilter={handleFilterByPid}
                      filterPlaceHolder= "PID..."
                      showUpdateButton={false}
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterName}
                      onFilter={handleFilterByName}
                      filterPlaceHolder= "Name..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterEmail}
                      onFilter={handleFilterByEmail}
                      filterPlaceHolder= "Email/Phone..."
                    />
                  </TableCell>
                  {/* <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterStreet}
                      onFilter={handleFilterByStreet}
                      filterPlaceHolder= "Street..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterSuburb}
                      onFilter={handleFilterBySuburb}
                      filterPlaceHolder= "Suburb..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterState}
                      onFilter={handleFilterByState}
                      filterPlaceHolder= "State..."
                    />
                  </TableCell>
                  <TableCell align="left" style={{padding:"0px"}} colSpan={1}>
                    <UserListToolbar
                      numSelected={selected.length}
                      filter={filterPostCode}
                      onFilter={handleFilterByPostCode}
                      filterPlaceHolder= "Postcode..."
                    />
                  </TableCell> */}
                  <TableCell colSpan={1} sx={{display:"flex"}}>
                    <Button variant="contained" onClick={searchRecord} sx={{backgroundColor:"rgb(92, 129, 228)",marginRight:"10px"}} startIcon={<SearchIcon />}>Search</Button>
                    <Button variant="contained" onClick={handleReset} sx={{backgroundColor:"red"}} startIcon={<ResetIcon />} >Reset</Button>
                  </TableCell>
                </TableRow>
                <TableBody>
                  {renderCardBody()}
                </TableBody>
              </Table>
              <Pagination sx={{float:"right",marginTop:"10px",marginBottom:"10px"}} page={page} count={totalPages} variant="outlined" color="primary" onChange={handlePageChange} />
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
      {
        open && 
          <ModalCompoennt open={open} onClose={closeButton} title="Update User Details" user={userObj} width="lg">
            <UpdateUser user={userObj} />
          </ModalCompoennt>
      }
    </Page>
  );
}

const mapStateToProps = state => {
  return {
    users:state.users,
    certificates:state.certificates
  }
}

export default connect(mapStateToProps, { searchUser, fetchCertificateList, fetchAllUsers, notifyUser, setUserVerTime, resetState, fetchUserDetails })(UsersList);