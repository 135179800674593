import axios from 'axios';

import { ADD_ALERT  } from './types';
import _ from 'lodash';
import shortId from 'shortid';

export const updateUserPassword = (values) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    const data = new FormData();
    data.append('token', values.token);
    data.append('password', values.password);
    axios
        .post("https://irhere.com.au/api/update_password", data, config)
        .then(function (response) {
            if(response.data.code == "1") {
                dispatch({
                    type: ADD_ALERT,
                    payload: {
                        key: shortId.generate(),
                        status: 'success',
                        color:'info',
                        message: response.data.desc,
                        errors: {}
                    }
                });
            } else if(response.data.code == "0") {
                dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'error',
                    color:'error',
                    message: response.data.desc,
                    errors: {}
                },
            });
        }
    })
    .catch(function (error) {
    });
}
