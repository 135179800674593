import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import moment from 'moment';
import Label from '../components/Label';
import { sentenceCase } from 'change-case';
import { UserListHead } from '../components/_dashboard/user';
import { useNavigate } from 'react-router-dom';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// material
import {
Card,
Table,
Box,
Button,
TableRow,
TableBody,
TableCell,
Container,
Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
// components
import Page from '../components/Page';
import ArrowBack from "@material-ui/icons/ArrowBack";



const SEC_TABLE_HEAD = [
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false }
];

const useStyles = makeStyles({
    root: {
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    card: {
        maxWidth: "100%",
        width:"auto",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        padding:"20px",
        textAlign:"left"
    }
});

function DashboardCert(props) {
    const classes = useStyles();
    const navigate = useNavigate();
    const [src, setSrc] = useState('');
    const [verId, setVerId] = useState('');
    const [verDate, setVerDate] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [quarantinDays, setQurantinDays] = useState('');
    const [status, setStatus] = useState('');
    const [trueStatus, setTrueStatus] = useState('');
    const [verificationHistory, setVerificationHistory] = useState([]);

    useEffect(() => {
        const qr = props.view.qr;
        const date = props.view.date;
        const finalDate = moment(date).format("D MMM, YYYY h:mm A");
        setSrc(qr);
        setVerId(props.view.id);
        setVerDate(finalDate);
        setName(props.view.name);
        setEmail(props.view.email);
        setAddress(props.view.address);
        setQurantinDays(props.view.quarantine_days);
        setStatus(props.view.status);
        setTrueStatus(props.view.true_status);
        setVerificationHistory(props.view.verification_history)
    }, [props.view])

    const renderList = () => {
        if(!_.isEmpty(verificationHistory)) {
            return verificationHistory.map((obj) => {
                return  <TableRow id={obj.date}>
                <TableCell sx={{padding:"5px 0px 5px 0px"}}>
                    {obj.desc}
                </TableCell>
                <TableCell sx={{padding:"5px 0px 5px 0px"}}>
                    {moment(obj.date).format("D MMM, YYYY h:mm A")}
                </TableCell>
            </TableRow>
            })
        }
    }

    const renderSuccesOrFailed = () => {
        const status_value = status;
        const true_status = trueStatus;
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() == "true") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    sx={{backgroundColor:"white", padding:"14px", border:"1px solid #01915f", borderRadius:"30px"}}
                >
                    <span style={{color:"#01915f"}}><TaskAltIcon /></span><span style={{fontSize:"17px", color:"#01915f"}}>{sentenceCase("Success")}</span>
                </Label>
                
            </Box>
        } 
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() == "false") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </Box>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "true") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'warning'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("pending")}
                </Label>
            </Box>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "false") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </Box>
        }
    }

    const handleClick = () => {
        navigate('/dashboard/app', { replace: true });
    }
    
    const renderStatus = () => {
        const status_value = status;
        const true_status = trueStatus;
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() == "true") {
            return <Box sx={{textAlign:"center", margin:"0px 0px 10px 10px"}}>
                <Label
                    variant="ghost"
                    sx={{backgroundColor:"#01915f", padding:"14px",borderRadius:"30px"}}
                >
                    <span style={{fontSize:"15px", color:"white"}}>{sentenceCase("verified")}</span>
                </Label>
                
            </Box>
        } 
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() == "false") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </Box>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "true") {
            return <>
                <Label
                    variant="ghost"
                    color={'warning'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("pending")}
                </Label>
            </>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "false") {
            return <>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </>
        }
    }

    return (
        <Page title="verification certificate">
            <Container maxWidth="100%">
                <Button onClick={handleClick} variant="outlined" startIcon={<ArrowBack />} sx={{marginY:"20px",background:"#5C81E4", color:"white", border:"none", '&:hover': {
                        background:"#5C81E4", 
                        color:"white",
                        border:"none"
                    },}}>
                    Go to Home
                </Button>
                <Card className={classes.card}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{padding:"0px",borderBottom:"0px"}}>
                                    <Typography variant="h3" sx={{padding:"0px", alignItems:"center", textAlign:"center", color:'#5C81E4' }}>
                                        <u>Validation Certificate</u>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom:"0px"}}>
                                    {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderSuccesOrFailed()}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{borderBottom:"2px dotted #5C81E4"}}>
                                <TableCell>
                                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center",marginBottom:"5px"}}>
                                        <Typography variant="body1" sx={{display:"flex", padding:"0px 10px 0px 15px"}}>
                                        <span style={{fontSize:"19px"}}>Address:</span> {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderStatus()}
                                        </Typography>
                                    </Box>
                                    <Box sx={{display:"flex", alignItems:"center", justifyContent:"center",marginBottom:"5px"}}>
                                        <Typography variant="body1" sx={{display:"flex", padding:"0px 10px 0px 15px"}}>
                                            <span style={{fontSize:"19px"}}>Identity:</span> {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderStatus()}
                                        </Typography>
                                    </Box>
                                    <Box sx={{marginBottom:"5px"}}>
                                        <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                            This Certifies that <br />
                                        </Typography>
                                        <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                            <b>{name}</b>
                                        </Typography>
                                    </Box>
                                    <Box sx={{marginBottom:"5px"}}>
                                        <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                            was located at
                                        </Typography>
                                        <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                            <b>{address}</b>
                                        </Typography>
                                    </Box>
                                    <Box sx={{marginBottom:"5px"}}>
                                        <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center" }}>
                                            on <br />
                                        </Typography>
                                        <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center" }}>
                                            <b>{verDate}</b>
                                        </Typography>
                                    </Box>
                                    <Box sx={{marginBottom:"8px"}}>
                                        { location.pathname == '/viewgeneralcert' && 
                                                <>
                                                <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                                    Initiated By <br />
                                                </Typography>
                                                <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center" }}>
                                                    <b>{initiatorName}</b>
                                                </Typography>
                                                </>

                                        }
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{border:"0px"}}>
                                    <Box alignItems="center" component="img" src={`data:image/jpeg;base64,${src}`} sx={{ display:"block", marginLeft:"auto", marginRight:"auto", width:"15%" }} /> 
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px",borderBottom:"0px"}}>
                                    <Box component="img" src={`data:image/jpeg;base64,${src}`} sx={{ width: "120px", height: "120" }} /> 
                                    <Box sx={{margin:"2px 0px 8px 10px"}}>{!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderStatus()}</Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px",borderBottom:"0px"}}>
                                    <Typography>
                                        <b>Verification Id:</b> {verId}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px",borderBottom:"0px"}}>
                                    <Typography>
                                    <b>Verification Date:</b> {verDate}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px 0px 10px 0px",borderBottom:"0px"}}>
                                    <Typography>
                                    <b>Quarantine Days:</b> {quarantinDays}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px"}}>
                                    <Typography variant="h5">
                                        Contact Details
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px",borderBottom:"0px"}}>
                                    <Typography>
                                        <b>Name:</b> {name}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px",borderBottom:"0px"}}>
                                    <Typography>
                                        <b>Email:</b> {email}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:"0px 0px 10px 0px",borderBottom:"0px"}}>
                                    <Typography>
                                        <b>Address:</b> {address}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{padding:"0px 0px 20px 0px"}} >
                                <TableCell sx={{padding:"0px"}}>
                                    <Typography variant="h5" sx={{marginY:"5px"}}>
                                        Validation History
                                    </Typography>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            <TableRow sx={{padding:"0px"}} >
                                <TableCell sx={{padding:"5px 0px 5px 0px"}}>
                                    <b>Description</b>
                                </TableCell>
                                <TableCell sx={{padding:"5px 0px 5px 0px"}}><b>Date</b></TableCell>
                            </TableRow>
                                {renderList()}
                            <TableRow>
                                <TableCell sx={{border:"0px", display:"flex",textAlign:"center",alignItems:"center",justifyContent:"center"}}>
                                    <CheckCircleIcon sx={{color:"#01915f",padding:"3px 3px 3px 3px"}} />
                                    <Typography variant="body1">
                                        Validated by IRhere using V+V Technology
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
            </Container>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        view: state.viewCert
    }
}

export default connect(mapStateToProps, {  })(DashboardCert);

