import axios from 'axios';

import { FETCH_CERTIFICATE_LIST, SEARCH_VALIDATION  } from './types';

export const fetchCertificateList= (bodyFormData,pageNumber) => async dispatch => {
    if(pageNumber >= 2) {
        await axios({
            method: "post",
            url: `https://irhere.com.au/api/get_certificate_list_all/page/${pageNumber}`,
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
        }).then(response => {
            dispatch({
                type:FETCH_CERTIFICATE_LIST, 
                payload:response.data
            });
        }).catch(error => {
            console.log(error)
        });
    } else {
        await axios({
            method: "post",
            url: `https://irhere.com.au/api/get_certificate_list_all`,
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
        }).then(response => {
            dispatch({
                type:FETCH_CERTIFICATE_LIST, 
                payload:response.data
            });
        }).catch(error => {
            console.log(error)
        });
    }
}

export const searchValidation = (search_ver_id) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    const fd = new FormData();
    fd.append('search_ver_id', search_ver_id);
    axios
        .post("https://irhere.com.au/api/search_certificate_list_ver_id", fd, config)
        .then(function (response) {
            dispatch({
                type:SEARCH_VALIDATION, 
                payload:response.data
            });
    })
    .catch(function (error) {
    });
}
