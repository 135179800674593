import axios from 'axios';
import _ from 'lodash';
import shortId from 'shortid';
import { CHECK_NOTIFICATION_TIME, ADD_ALERT, UPDATE_NOTIFICATION_TIME  } from './types';

export const checkNotificationTime= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/check_notification_time",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:CHECK_NOTIFICATION_TIME, 
            payload:response.data
        });
    }).catch(error => {
    });
}

export const setNotificationTime= (bodyFormData, fDate) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/set_notification_time",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        if(response.data.code == '1') {
            dispatch({
                type: UPDATE_NOTIFICATION_TIME,
                payload: {
                    payload: fDate
                }
            });
            dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'success',
                    color:'info',
                    message: response.data.desc,
                    errors: {}
                }
            });
        }
        
    }).catch(error => {
    });
}