import axios from 'axios';

import { FETCH_ALL_USERS, NOTIFY_USER, ADD_ALERT, SEARCH_USER  } from './types';
import _ from 'lodash';
import shortId from 'shortid';

// export const fetchAllUsers= (bodyFormData,pageNumber) => async dispatch => {
//     if(pageNumber >= 2) {
//         await axios({
//             method: "post",
//             url: `https://irhere.com.au/api/get_user_list/page/${pageNumber}`,
//             data: bodyFormData,
//             headers: { 
//                 "Content-Type": "multipart/form-data",
//                 "Access-Control-Allow-Origin": "*",
//                 mode: "cors",
//             },
//         }).then(response => {
//             dispatch({
//                 type:FETCH_ALL_USERS, 
//                 payload:response.data
//             });
//         }).catch(error => {
//         });
//     } else {
//         await axios({
//             method: "post",
//             url: "https://irhere.com.au/api/get_user_list",
//             data: bodyFormData,
//             headers: { 
//                 "Content-Type": "multipart/form-data",
//                 "Access-Control-Allow-Origin": "*",
//                 mode: "cors",
//             },
//         }).then(response => {
//             dispatch({
//                 type:FETCH_ALL_USERS, 
//                 payload:response.data
//             });
//         }).catch(error => {
//         });
//     }
// }

export const fetchAllUsers= (bodyFormData,pageNumber) => async dispatch => {
    if(pageNumber >= 2) {
        await axios({
            method: "post",
            url: `https://irhere.com.au/api/get_user_list_gen/page/${pageNumber}`,
            data: bodyFormData,
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
        }).then(response => {
            dispatch({
                type:FETCH_ALL_USERS, 
                payload:response.data
            });
        }).catch(error => {
        });
    } else {
        await axios({
            method: "post",
            url: "https://irhere.com.au/api/get_user_list_gen",
            data: bodyFormData,
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
        }).then(response => {
            dispatch({
                type:FETCH_ALL_USERS, 
                payload:response.data
            });
        }).catch(error => {
        });
    }
}

export const notifyUser= (fcmToken) => async dispatch => {
    await axios({
        method: "post",
        url: "https://fcm.googleapis.com/fcm/send",
        data: {
            "to": fcmToken,
            "priority": "high",
            "content_available": true,
            "notification":{
                "title":"Verify your location",
                "body":"You have received a request to verify your location. You have 20 minutes to respond.",
                "visibility": "PUBLIC",
                "sound": "default",
                "badge": 1
            },
        },
        headers: { 
            "Content-Type": "application/json",
            "Authorization":"key=AAAAZZH6qhE:APA91bEruvPmqITrXM1wKzgdTKAL2CIki_PoqsloX-Y5M4skH9tS5o6LEtrzEpsT3ke73OlS6gtyi4r_h6hTvZClUOD2H9FlSuoCg2ak4HEOoNNG7PLaMNGOCOzMXINV5pzN-IdXNr3R"
        },
    }).then(response => {
        if(!_.isEmpty(response.data)) {
            dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'success',
                    color:'info',
                    message: "Notification is sent successfully ",
                    errors: {}
                }
            });
        }
    }).catch(error => {
    });
}

export const setUserVerTime= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/set_user_vertime",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
    }).catch(error => {
    });
}

export const resetUser= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/reset_user",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        if(response.data.code == '1') {
            dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'success',
                    color:'info',
                    message: response.data.desc,
                    errors: {}
                }
            });
        }
    }).catch(error => {
    });
}

export const sendNotification= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/send_notification",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        
    }).catch(error => {
    });
}

export const searchUser = (search_pid) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    const fd = new FormData();
    fd.append('apikey','68685dc6-5fb7-46c6-8cd5-228fc33b5485')
    fd.append('pid', search_pid);
    axios
        .post("https://irhere.com.au/api/get_user_list_gen_search", fd, config)
        .then(function (response) {
            dispatch({
                type:SEARCH_USER, 
                payload:response.data
            });
    })
    .catch(function (error) {
    });
}
