import { 
    ADD_ALERT,
    FETCH_ALL_ADMINS,
    UPDATE_ADMIN_DETAILS,
    DELETE_ADMIN
} from './types';
import shortId from "shortid";
import axios from 'axios';

export const addAdmin = (values) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    const data = new FormData();
    data.append('name', values.name);
    data.append('email', values.email);
    data.append('password', values.password);
    axios
        .post("https://irhere.com.au/api/sign_up_admin", data, config)
        .then(function (response) {
            if(response.data.code == "1") {
                dispatch({
                    type: ADD_ALERT,
                    payload: {
                        key: shortId.generate(),
                        status: 'success',
                        color:'info',
                        message: response.data.desc,
                        errors: {}
                    }
                });
            } else if(response.data.code == "0") {
                dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'error',
                    color:'error',
                    message: response.data.desc,
                    errors: {}
                },
            });
        }
    })
    .catch(function (error) {
    });
}

export const updateAdminDeatils = (values, valuesForReducer) => dispatch => {
    var config = {
        headers: {
            "Content-Type": "multipart/form-data"
        },
    };
    axios
        .post("https://irhere.com.au/api/update_admin_name", values, config)
        .then(function (response) {
            if(response.data.code == "1") {
                dispatch({
                    type: ADD_ALERT,
                    payload: {
                        key: shortId.generate(),
                        status: 'success',
                        color:'info',
                        message: response.data.desc,
                        errors: {}
                    }
                });
                dispatch({
                    type:UPDATE_ADMIN_DETAILS, 
                    payload: {
                        payload: valuesForReducer
                    }
                });
            } else if(response.data.code == "0") {
                dispatch({
                type: ADD_ALERT,
                payload: {
                    key: shortId.generate(),
                    status: 'error',
                    color:'error',
                    message: response.data.desc,
                    errors: {}
                },
            });
        }
    })
    .catch(function (error) {
    });
}

export const fetchAllAdmins= (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/fetch_admin_list",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:FETCH_ALL_ADMINS, 
            payload:response.data
        });
    }).catch(error => {
    });
}

export const deleteAdmin= (bodyFormData, user_id) => async dispatch => {
    await axios({
        method: "post",
        url: "https://irhere.com.au/api/delete_admin",
        data: bodyFormData,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:DELETE_ADMIN, 
            payload:user_id
        });
        dispatch({
            type: ADD_ALERT,
            payload: {
                key: shortId.generate(),
                status: 'success',
                color:'info',
                message: response.data.desc,
                errors: {}
            }
        });
    }).catch(error => {
    });
}