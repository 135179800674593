// import * as Yup from 'yup';
// import { useState, useEffect } from 'react';
// import { Link as RouterLink, useNavigate } from 'react-router-dom';
// import { useFormik, Form, FormikProvider } from 'formik';
// import { connect } from "react-redux";
// import { Icon } from '@iconify/react';
// import eyeFill from '@iconify/icons-eva/eye-fill';
// import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// // material
// import {
//     Card,
//     Stack,
//     TextField,
//     IconButton,
//     InputAdornment,
//     Container,
//     Typography
    
// } from '@mui/material';
// import { LoadingButton } from '@mui/lab';
// import _ from 'lodash';
// import Page from '../components/Page';
// import { styled } from '@mui/material/styles';


// // ----------------------------------------------------------------------

// const RootStyle = styled(Page)(({ theme }) => ({
//     [theme.breakpoints.up('md')]: {
//         display: 'flex'
//     }
// }));

// const ContentStyle = styled('div')(({ theme }) => ({
//     maxWidth: 680,
//     margin: 'auto',
//     minHeight: '100vh',
//     flexDirection: 'column',
//     justifyContent: 'center',
// }));

// function UserPasswordReset(props) {
//     const navigate = useNavigate();
//     const [showPassword, setShowPassword] = useState(false);
//     const [showRepeatPassword, setShowRepeatPassword] = useState(false);
//     const [isLoading, setIsLoading] = useState(false);
//     const AddSchema = Yup.object().shape({
//         password: Yup.string().required('Password is required'),
//         repeat_password: Yup.string().required('Repeat Password is required')
//             .oneOf([Yup.ref('password'), null], 'Passwords must match')
//     });

//     const formik = useFormik({
//         initialValues: {
//             password: '',
//             repeat_password:''
//         },
//     validationSchema: AddSchema,
//         onSubmit: (values, {resetForm}) => {
//             resetForm();
//             setIsLoading(true);
//             // props.addAdmin(values);
//         }
//     });

//     useEffect(() => {
//         setIsLoading(false);
//     }, [props.alerts]);

//     useEffect(() => {
//         // var bodyFormData = new FormData();
//         // bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
//         // props.fetchAllAdmins(bodyFormData);
//     }, []);

//     const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

//     const handleShowPassword = () => {
//         setShowPassword((show) => !show);
//     };

//     const handleShowRepeatPassword = () => {
//         setShowConfirmPassword((show) => !show);
//     };

//     // const handleChangeRowsPerPage = (event) => {
//     //     setRowsPerPage(parseInt(event.target.value, 10));
//     //     setPage(0);
//     // };

//     // const handleChangePage = (event, newPage) => {
//     //     setPage(newPage);
//     // };

//     // const handleUpdateClick = (user) => {
//     //     setOpenUpdate(true);
//     //     setAdminObj(user);
//     // }

//     // const handleDeleteClick = (user) => {
//     //     setOpenDelete(true);
//     //     setAdminObj(user);
//     // }

//     // const closeButton = () => {
//     //     setOpenUpdate(false);
//     //     setOpenDelete(false);
//     //     setAdminObj({});
//     // }

//     // const filteredUsers = applyFilter(props.admins);

//     // const isUserNotFound = filteredUsers.length === 0;

//     return (
//         <RootStyle title="UserPasswordReset">
//             <Container maxWidth="lg" sx={{width:"50%"}}>
//         <ContentStyle>
//                 <Card sx={{padding:"1em"}}>
//                     <Stack sx={{ mb: 2 }}>
//                         <Typography variant="h4" gutterBottom>
//                             Update Password
//                         </Typography>
//                     </Stack>
//                     <FormikProvider value={formik}>
//                         <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
//                             <Stack spacing={3}>
//                                 <TextField
//                                     fullWidth
//                                     autoComplete="current-password"
//                                     type={showPassword ? 'text' : 'password'}
//                                     label="New Password*"
//                                     {...getFieldProps('password')}
//                                     InputProps={{
//                                     endAdornment: (
//                                         <InputAdornment position="end">
//                                         <IconButton onClick={handleShowPassword} edge="end">
//                                             <Icon icon={showPassword ? eyeFill : eyeOffFill} />
//                                         </IconButton>
//                                         </InputAdornment>
//                                     )
//                                     }}
//                                     error={Boolean(touched.password && errors.password)}
//                                     helperText={touched.password && errors.password}
//                                 />
//                                 <TextField
//                                     fullWidth
//                                     autoComplete="current-password"
//                                     type={showRepeatPassword ? 'text' : 'password'}
//                                     label="Repeat Password*"
//                                     {...getFieldProps('repeat_password')}
//                                     InputProps={{
//                                         endAdornment: (
//                                         <InputAdornment position="end">
//                                             <IconButton onClick={handleShowRepeatPassword} edge="end">
//                                             <Icon icon={showRepeatPassword ? eyeFill : eyeOffFill} />
//                                             </IconButton>
//                                         </InputAdornment>
//                                         )
//                                     }}
//                                     error={Boolean(touched.repeat_password && errors.repeat_password)}
//                                     helperText={touched.repeat_password && errors.repeat_password}
//                                     />
//                             </Stack>
//                             <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
//                             </Stack>

//                             <LoadingButton
//                                 fullWidth
//                                 size="large"
//                                 type="submit"
//                                 variant="contained"
//                                 loading={isLoading}
//                                 sx={{backgroundColor: '#5C81E4',}}
//                             >
//                                 Update
//                             </LoadingButton>
//                         </Form>
//                     </FormikProvider>
//                 </Card>
//         </ContentStyle>
//             </Container> 

//     </RootStyle>
//     );
// }
// const mapStateToProps = (state) => {
//     return {
//         auth: state.auth,
//         alerts: state.alerts,
//     }
// }

// export default connect(mapStateToProps, { })(UserPasswordReset);

import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { UpdateForm } from '../components/passwordupdate/index';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex'
    }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
    width: '100%',
    maxWidth: 464,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
}));

// ----------------------------------------------------------------------

export default function UserPasswordReset() {
    return (
        <RootStyle title="Login">
            <Container maxWidth="sm">
                <ContentStyle>
                    <Stack sx={{ mb: 3 }}>
                        <Typography variant="h4" gutterBottom>
                            Update Password
                        </Typography>
                    </Stack>
                <UpdateForm />
                </ContentStyle>
            </Container>
        </RootStyle>
    );
}

