import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
import { connect } from "react-redux";
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
    Link,
    Card,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel,
    Container,
    Typography,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    
} from '@mui/material';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../../components/_dashboard/user';
import { LoadingButton } from '@mui/lab';
import Scrollbar from '../../components/Scrollbar';
import { addAdmin, fetchAllAdmins } from '../../actions/adminAction';
import _ from 'lodash';
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';
import Edit from "@material-ui/icons/Edit";
import DeleteOutlined from "@material-ui/icons/DeleteOutlined";
import ModalCompoennt from '../Portal/ModalCompoennt';
import UpdateAdmin from './UpdateAdmin';
import DeleteAdmin from './DeleteAdmin';


// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
}));

const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'action', label: 'Actions', alignRight: false },
];

    const applyFilter = (admins) => {
        const stabilizedThis = admins.map((el, index) => [el, index]);
        return stabilizedThis.map((el) => el[0]);
    }

function AddAdmin(props) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [adminObj, setAdminObj] = useState({});
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const AddSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        password: Yup.string().required('Password is required'),
        confirm_password: Yup.string().required('Confirm Password is required')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const formik = useFormik({
        initialValues: {
            name:'',
            email: '',
            password: '',
            confirm_password:''
        },
    validationSchema: AddSchema,
        onSubmit: (values, {resetForm}) => {
            resetForm();
            setIsLoading(true);
            props.addAdmin(values);
        }
    });

    useEffect(() => {
        setIsLoading(false);
    }, [props.alerts]);

    useEffect(() => {
        var bodyFormData = new FormData();
        bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
        props.fetchAllAdmins(bodyFormData);
    }, []);

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword((show) => !show);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleUpdateClick = (user) => {
        setOpenUpdate(true);
        setAdminObj(user);
    }

    const handleDeleteClick = (user) => {
        setOpenDelete(true);
        setAdminObj(user);
    }

    const closeButton = () => {
        setOpenUpdate(false);
        setOpenDelete(false);
        setAdminObj({});
    }

    const filteredUsers = applyFilter(props.admins);

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <Page title="AdminSignUp">
            <Container maxWidth="lg" sx={{width:"50%"}}>
                <Card sx={{padding:"1em"}}>
                    <Stack sx={{ mb: 5 }}>
                        <Typography variant="h4" gutterBottom>
                            Add Other Admins
                        </Typography>
                        <Typography sx={{ color: 'text.secondary' }}>Enter details below.</Typography>
                    </Stack>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Full Name*"
                                    {...getFieldProps('name')}
                                    error={Boolean(touched.name && errors.name)}
                                    helperText={touched.name && errors.name}
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="username"
                                    type="email"
                                    label="Email Address*"
                                    {...getFieldProps('email')}
                                    error={Boolean(touched.email && errors.email)}
                                    helperText={touched.email && errors.email}
                                />

                                <TextField
                                    fullWidth
                                    autoComplete="current-password"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password*"
                                    {...getFieldProps('password')}
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton onClick={handleShowPassword} edge="end">
                                            <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                                        </IconButton>
                                        </InputAdornment>
                                    )
                                    }}
                                    error={Boolean(touched.password && errors.password)}
                                    helperText={touched.password && errors.password}
                                />
                                <TextField
                                    fullWidth
                                    autoComplete="current-password"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    label="Confirm Password*"
                                    {...getFieldProps('confirm_password')}
                                    InputProps={{
                                        endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={handleShowConfirmPassword} edge="end">
                                            <Icon icon={showConfirmPassword ? eyeFill : eyeOffFill} />
                                            </IconButton>
                                        </InputAdornment>
                                        )
                                    }}
                                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                                    helperText={touched.confirm_password && errors.confirm_password}
                                    />
                            </Stack>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                            </Stack>

                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isLoading}
                                sx={{backgroundColor: '#5C81E4',}}
                            >
                                Add
                            </LoadingButton>
                        </Form>
                    </FormikProvider>
                </Card>
            </Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                    <Typography variant="h4" gutterBottom>
                        All Admins
                    </Typography>
                </Stack>

                <Card>
                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 800 }}>
                            <Table>
                                <UserListHead
                                    headLabel={TABLE_HEAD}
                                    rowCount={props.admins.length}
                                />
                                <TableBody>
                                    {filteredUsers
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((user) => {
                                        return (
                                            <TableRow
                                                hover
                                                key={user.id}
                                                tabIndex={-1}
                                            >
                                                <TableCell align="left" style={{padding:"12px"}}>{user.id}</TableCell>
                                                <TableCell align="left" style={{padding:"12px"}}>{user.name}</TableCell>
                                                <TableCell align="left" style={{padding:"12px"}}>{user.email}</TableCell>
                                                <TableCell align="center" style={{padding:"12px"}}>
                                                    <IconButton onClick={() => handleUpdateClick(user)} sx={{float:"left",padding:"0px", color:"#5C81E4"}}>
                                                        <Edit />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleDeleteClick(user)} sx={{float:"left",padding:"0px", color:"#5C81E4"}}>
                                                        <DeleteOutlined />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                            );
                                        })}
                                </TableBody>
                                    {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                        
                                        </TableRow>
                                    </TableBody>
                                    )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[10, 15, 20]}
                        component="div"
                        count={props.admins.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Card>
                {
                    openUpdate && 
                    <ModalCompoennt open={openUpdate} onClose={closeButton} title="Update" width="sm">
                        <UpdateAdmin admin={adminObj} onDismis={closeButton} />
                    </ModalCompoennt>
                }
                {
                    openDelete && 
                    <ModalCompoennt open={openDelete} onClose={closeButton} title="Delete" width="xs" color="red">
                        <DeleteAdmin admin={adminObj} onDismis={closeButton} />
                    </ModalCompoennt>
                }
                
        </Page>
    );
}
const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        alerts: state.alerts,
        admins: Object.values(state.admins.data),
    }
}

export default connect(mapStateToProps, { addAdmin, fetchAllAdmins })(AddAdmin);

