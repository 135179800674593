import { VIEW_CERT, RESET } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case VIEW_CERT:
            return { ...state, ...action.payload }
        case RESET:
            return {}
        default: return state;
    }
}