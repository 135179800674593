import { LOG_OUT } from '../actions/types';
import {combineReducers} from 'redux';
import certificateListReducer from './certificateListReducer';
import userListReducer from './userListReducer';
import alertReducer from './alertReducer';
import authReducer from './authReducer';
import userDetailsReducer from './userDetailsReducer';
import verificationDetailsReducer from './verificationDetailsReducer';
import viewCertReducer from './viewCertReducer';
import notificationStatus from './notificationStatus';
import adminListReducer from './adminListReducer';
import settingsReducer from './settingsReducer';
import certImagesReducer from './certImagesReducer';
import transactionReducer from './transactionReducer';
import vouchersReducer from './vouchersReducer';
import termsReducer from './termsReducer';

const appReducer = combineReducers ({
    users:userListReducer,
    certificates:certificateListReducer,
    alerts: alertReducer,
    auth:authReducer,
    userDetails:userDetailsReducer,
    verificationDetails:verificationDetailsReducer,
    viewCert: viewCertReducer,
    notificationStatus: notificationStatus,
    admins:adminListReducer,
    settings:settingsReducer,
    certImages: certImagesReducer,
    transactions: transactionReducer,
    vouchers:vouchersReducer,
    terms:termsReducer
});

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === LOG_OUT) {
        sessionStorage.removeItem("isSignedIn");
        sessionStorage.removeItem("user");
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;

