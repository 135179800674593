import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux';
// components
import { viewCert, viewGeneralCert } from '../actions/viewCertAction';
import { fetchCertImages } from '../actions/fetchCertImagesAction';
import GeneralCert from './GeneralCert';
import DashboardCert from './DashboardCert';
import { RESET } from "../actions/types";
import { resetState } from '../actions/resetAction';
import { LeakAddSharp } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';


// ----------------------------------------------------------------------

function ViewCert(props) {
    const isLoggedIn  = sessionStorage.getItem("isSignedIn");
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');
    const location = useLocation();

    useEffect(() => {
        if(location.pathname == '/viewcert' && location.search.includes('?id=')) {
            var bodyFormData = new FormData();
            bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
            bodyFormData.append('ver_id', id);
            props.viewCert(bodyFormData);
        } else if(location.pathname == '/viewgeneralcert' && location.search.includes('?id=')) {
            var bodyFormData = new FormData();
            bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
            bodyFormData.append('ver_id', id);
            props.viewGeneralCert(bodyFormData);
            props.fetchCertImages(bodyFormData);
        }
        return () => { 
            props.resetState(RESET);
        }
    }, [id]);

    return (
        <GeneralCert />
    );
}


const mapStateToProps = state => {
    return {
        viewCert: state.viewCert
    }
}

export default connect(mapStateToProps, { viewCert, resetState, viewGeneralCert, fetchCertImages })(ViewCert);