import React from 'react';
import { useState, useEffect, useMemo } from 'react';
import { sentenceCase } from 'change-case';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import moment from 'moment';
import { ceil, filter } from 'lodash';
import {
Link,
Card,
Stack,
Typography,
TableContainer,
TableHead,
Table,
TableBody,
TableRow,
TableCell,
Grid,
Box,
Button,
Container,
TablePagination,
} from '@mui/material';
import Scrollbar from '../../components/Scrollbar';
import { fetchVouchersList } from '../../actions/vouchersAction';
import _ from 'lodash';
import Page from '../../components/Page';
import { styled } from '@mui/material/styles';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import { UserListHead, UserListToolbar, UserMoreMenu, UserDatePicker } from '../../components/_dashboard/user';
import FilterVouchers from '../../components/_dashboard/Vouchers/FilterVouchers';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Label from '../../components/Label';
import ModalCompoennt from '../Portal/ModalCompoennt';
import CreateVoucher from './CreateVoucher';
import SearchNotFound from '../../components/SearchNotFound';
import Pagination from '@mui/material/Pagination';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'voucher_code', label: 'Voucher Code', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'expiry_date', label: 'Expiry Date', alignRight: false },
    { id: 'amount', label: 'Amount', alignRight: false },
    { id: 'is_used', label: 'Is Used', alignRight: false },
    // { id: 'actions', label: 'Actions' },
];

// ----------------------------------------------------------------------

const ContentStyle = styled('div')(({ theme }) => ({
    maxWidth: 680,
    margin: 'auto',
    minHeight: '100vh',
    flexDirection: 'column',
    justifyContent: 'center',
})); 


function VouchersList(props) {
    const [page, setPage] = useState(0);
    const [selected, setSelected] = useState([]);
    const [filterVoucherCode, setFilterVoucherCode] = useState('');
    const [filterUsed, setFilterUsed] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [createOpen, setCreateOpen] = useState(false);


    const closeButton = () => {
        setCreateOpen(false);
    }

    const getVouchersByStatus = (a) => {
        if(filterUsed == 0 && a.is_used == 0 && moment(new Date().getTime()).format("YYYY-MM-DD h:mm:ss a") < moment(new Date(a.valid_date).getTime()).format("YYYY-MM-DD h:mm:ss a")) {
            return a;
        }
        if(filterUsed == 1 && a.is_used == 0 && moment(new Date().getTime()).format("YYYY-MM-DD h:mm:ss a") > moment(new Date(a.valid_date).getTime()).format("YYYY-MM-DD h:mm:ss a")) {
            return a;
        }
        if(filterUsed == 2 && a.is_used == 1) {
            return a;
        }
    }

    const applyFilter = (array) => {
        const stabilizedThis = array.map((el, index) => ([el, index+1]));
        if (filterVoucherCode != "" || !_.isEmpty(filterUsed)) {
            return filter(array, (a) => 
                a.voucher_code.toLowerCase().indexOf(filterVoucherCode.toLowerCase()) !== -1 &&
                getVouchersByStatus(a)
            );
        }
        return stabilizedThis.map((el) => el[0])
    }
    
    useEffect(() => {
        props.fetchVouchersList();
    }, []);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = props.certificates.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByVocherCode = (event) => {
        setFilterVoucherCode(event.target.value);
    };

    const handleFilterByUsed = (event) => {
        setFilterUsed(event.target.value);
    };

    const renderIsUsed = (v) => {
        if(v.is_used == 1) {
            return <>
                <Label
                    variant="ghost"
                    color={'success'}
                >
                    {sentenceCase("used")}
                </Label>
            </>
        }
        if(v.is_used == 0) {
            if(moment(new Date().getTime()).format("YYYY-MM-DD h:mm:ss a") >  moment(new Date(v.valid_date).getTime()).format("YYYY-MM-DD h:mm:ss a")) {
                return <>
                    <Label
                        variant="ghost"
                        color={'error'}
                    >
                        {sentenceCase("expired")}
                    </Label>
                </>
            } else {
                return <>
                    <Label
                        variant="ghost"
                        color={'info'}
                    >
                        {sentenceCase("not used")}
                    </Label>
                </>
            }
        }
    }

    const filteredVouchers = applyFilter(props.vouchers);

    const isVoucherNotFound = filteredVouchers.length === 0;

    return (
        <Page title="Vouchers List">
            <Container maxWidth="100%">
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <Typography variant="h4" gutterBottom>
                            Vouchers List
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Button variant="contained" onClick={() => setCreateOpen(true)} sx={{backgroundColor:"rgb(92, 129, 228)"}}>Create Voucher</Button>
                    </Grid>
                </Grid>
                </Stack>
                <Card>
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                headLabel={TABLE_HEAD}
                                rowCount={props.vouchers.length}
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableRow>
                                <TableCell align="left" style={{padding:"0px"}} colSpan={2}>
                                    <UserListToolbar
                                        numSelected={selected.length}
                                        filter={filterVoucherCode}
                                        onFilter={handleFilterByVocherCode}
                                        filterPlaceHolder= "Search By Voucher Code..."
                                    />
                                </TableCell>
                                <TableCell align="left" style={{padding:"0px"}} colSpan={3}>
                                    <FilterVouchers
                                        numSelected={selected.length}
                                        filter={filterUsed}
                                        onFilter={handleFilterByUsed}
                                        filterPlaceHolder= "Search By Valid & Expired..."
                                    />
                                </TableCell>
                            </TableRow>
                            <TableBody>
                                {filteredVouchers
                                    .slice(page * 10, page * 10 + 10)
                                    .map((v) => {
                                        return (
                                            <TableRow
                                                hover
                                                key={v.id}
                                            >
                                                <TableCell align="left" style={{padding:"10px", width:"auto"}}>{v.voucher_code}</TableCell>
                                                <TableCell align="left" style={{padding:"10px", width:"auto"}}>{v.date}</TableCell>
                                                <TableCell align="left" style={{padding:"10px", width:"auto"}}>{v.valid_date}</TableCell>
                                                <TableCell align="left" style={{padding:"10px", width:"auto"}}>{v.amount}</TableCell>
                                                <TableCell align="left" style={{padding:"10px", width:"auto"}}>{renderIsUsed(v)}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                                {isVoucherNotFound && (
                                    <TableBody>
                                        <TableRow>
                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterVoucherCode} />
                                        </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                        </Table>
                    </TableContainer>
                </Scrollbar>
                <TablePagination
                    component="div"
                    count={props.vouchers.length}
                    labelRowsPerPage={''}
                    rowsPerPageOptions={[10]}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelDisplayedRows={({from,page,to,count}) => {
                        return ``;
                    }}
                    showFirstButton={true}
                    showLastButton={true}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Card> 
            </Container>
            {
                createOpen && 
                <ModalCompoennt open={createOpen} onClose={closeButton} title="Create New Voucher" width="sm">
                    <CreateVoucher onDismis={closeButton} />
                </ModalCompoennt>
            }
        </Page>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        alerts: state.alerts,
        vouchers: Object.values(state.vouchers.data),
    }
}
export default connect(mapStateToProps, { fetchVouchersList })(VouchersList);