import React, { Component } from 'react'
import { useEffect, useState } from 'react';
import {
    MenuItem,
    Grid,
    Box,
    Card,
    TextField,
    Table,
    TableRow,
    TableBody,
    TableCell,
} from '@mui/material';
import _ from 'lodash';
import { connect } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/MobileDateTimePicker';
import moment from 'moment';
import { setNotificationTime } from '../actions/notificationStatus';
import { LoadingButton } from '@mui/lab';
import Select from '@mui/material/Select';


const useStyles = makeStyles({
    root: {
        minWidth: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    card: {
        maxWidth: "100%",
        width:"auto",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        padding:"10px",
        textAlign:"left"
    }
});


function UpdateNotificationDetails(props) {
    const classes = useStyles();
    const [date, setDate] = React.useState(new Date().setMinutes(new Date().getMinutes() + Number(props.verification_timeout)));
    const [isLoading, setIsLoading] = useState(false);
    const [chunk, setChunk] = useState('0');

    const handleDate = (event) => {
        setDate(event);
    };

    const handleChunkChange = (event) => {
        setChunk(event.target.value);
    };

    useEffect(() => {
        setIsLoading(false);
    }, [props.alerts]);

    const handleSubmit = () => {
        var bodyFormData = new FormData();
        const fDate = moment(new Date(date)).format("YYYY-MM-D HH:mm:ss");
        bodyFormData.append('date', fDate);
        bodyFormData.append('chunk', chunk != '' ? chunk : 0);
        props.setNotificationTime(bodyFormData, fDate);
        props.onDismis();
    }

    return (
        <Grid
            container
            className={classes.root}
            spacing={0}
            alignItems="center"
            justify="center"
        >
            <Card className={classes.card}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{padding:"5px",borderBottom:"0px"}}><b>Select Date:</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{padding:"15px",borderBottom:"0px",float:"right"}}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} sx={{padding:"0px",width:"100%"}}>
                                    <DatePicker
                                        size="small"
                                        formatDate={(date) => moment(new Date()).format("D MMM, YYYY h:mm a")}
                                        value={date}
                                        minDateTime={new Date().setMinutes(new Date().getMinutes() + Number(props.verification_timeout))}
                                        onChange={handleDate}
                                        renderInput={(params) => <TextField 
                                            size="small" 
                                            {...params}
                                        />
                                        }
                                    />
                                </LocalizationProvider>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{padding:"5px", borderBottom:"0px"}}><b>Notification Frequency:</b></TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{padding:"5px", borderBottom:"0px", maxWidth:"100px", lineHeight:"1.2em" }}>How many times a notification will be delivered in a day</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Select
                                    size='small'
                                    sx={{width:"100%"}}
                                    value={chunk}
                                    onChange={handleChunkChange}
                                >
                                    <MenuItem value={0} >0</MenuItem>
                                    <MenuItem value={1}>1</MenuItem>
                                    <MenuItem value={2}>2</MenuItem>
                                    <MenuItem value={3}>3</MenuItem>
                                    <MenuItem value={4}>4</MenuItem>
                                </Select>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell sx={{padding:"15px",borderBottom:"0px"}}>
                                <LoadingButton
                                    size="medium"
                                    type="submit"
                                    variant="contained"
                                    loading={isLoading}
                                    sx={{backgroundColor: '#5C81E4',float:"right"}}
                                    onClick={handleSubmit}
                                >
                                    Update
                                </LoadingButton>
                            </TableCell>
                        </TableRow>
                        
                    </TableBody>
                </Table>
            </Card>
        </Grid>
    )
}

const mapStateToProps = state => {
    return {
        alerts: state.alerts,
    }
}

export default connect(mapStateToProps, { setNotificationTime })(UpdateNotificationDetails);

