import React from 'react'
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect, useRef } from 'react';
import moment from 'moment';
// material
import {
  Card,
  Table,
  Stack,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Button
} from '@mui/material';
import { connect } from 'react-redux';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu, UserDatePicker, SearchValidationToolBar } from '../components/_dashboard/user';
import { fetchAllUsers, notifyUser, setUserVerTime, resetUser } from '../actions/userListAction';
import { searchValidation } from '../actions/certificateListAction';
import { fetchVerificationDetails } from '../actions/verificationDetailsAction';
import _ from 'lodash';
import { resetState } from '../actions/resetAction';
import { RESET } from "../actions/types";
import ModalCompoennt from './Portal/ModalCompoennt';
import VerificationDetails  from './VerificationDetails';
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@mui/material/Pagination';
import SearchIcon from '@mui/icons-material/Search';
import ResetIcon from '@mui/icons-material/Restore';
import { Link as RouterLink, useNavigate } from 'react-router-dom';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "70%",
  height:"90%",
  bgcolor: 'background.paper',
  boxShadow: 50,
  border:"none"
};

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap"
  },
  textField: {
  },
  '&.css-1s2atx1-MuiButtonBase-root-MuiIconButton-root':{
    padding:0
  }
}));

function SearchValidation(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [selected, setSelected] = useState([]);
  const [filterVid, setFilterVid] = useState('');
  const [error, setError] = useState('');
  const [open, setOpen] = useState(false);
  const isFirstRun = useRef(true);
  const [userObj, setUserObj] = useState({});

  const handleFilterByVid = (event) => {
    setFilterVid(event.target.value);
  };

  const searchRecord = () => {
    if(filterVid != '' ) {
      setError('');
      props.searchValidation(filterVid);
    } else {
      console.log('insert record');
    }
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if(!_.isEmpty(Object.values(props.certificates.data))) {
      const ver_id = Object.values(props.certificates.data)[0].ver_id;
      navigate(`/viewgeneralcert?id=${ver_id}`, { replace: true });
    } else {
      setError('No record found!');
    }
  },[props.certificates.data])

  return (
    <Page title="Search Validation">
      <Container maxWidth="100%">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Search Validation
          </Typography>
        </Stack>
        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableRow sx={{width:"100%"}}>
                  <TableCell align="left" style={{padding:"0px", width:"90%"}}>
                    <SearchValidationToolBar
                      numSelected={selected.length}
                      filter={filterVid}
                      onFilter={handleFilterByVid}
                      filterPlaceHolder= "Search by Validation Number..."
                      showUpdateButton={false}
                    />
                  </TableCell>
                  <TableCell sx={{width:"10%"}}>
                    <Button variant="contained" onClick={searchRecord} sx={{backgroundColor:"rgb(92, 129, 228)",'&.MuiButton-root':{
                        boxShadow:"0 8px 16px 0 rgb(0 0 0 / 30%)"
                    }}} startIcon={<SearchIcon />}>Search</Button>
                  </TableCell>
                </TableRow>
                <TableRow sx={{width:"100%"}}>
                  <TableCell align="left" style={{padding:"15px", width:"90%"}}>
                    <Typography variant='subtitle1'>{error}</Typography>
                  </TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  )
}
const mapStateToProps = state => {
  return {
    certificates:state.certificates,
  }
}
export default connect(mapStateToProps, { searchValidation })(SearchValidation);
