import { FETCH_TERMS, UPDATE_IRHERE_TERMS, UPDATE_BVDT_TERMS, UPDATE_AVDT_TERMS } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {
    i_terms: "",
    bvdt: "",
    avdt: ""
}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_TERMS:
            return { ...state, i_terms:action.payload.terms, bvdt:action.payload.bvdt, avdt:action.payload.avdt }
        case UPDATE_IRHERE_TERMS:
            return { ...state, i_terms:action.payload }
        case UPDATE_BVDT_TERMS:
            return { ...state, bvdt:action.payload }
        case UPDATE_AVDT_TERMS:
            return { ...state, avdt:action.payload }
        default: return state;
    }
}