import PropTypes from 'prop-types';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';

// material
import { styled } from '@mui/material/styles';
import {
    Box,
    Toolbar,
    Tooltip,
    IconButton,
    Typography,
    OutlinedInput,
    InputAdornment,
    TextField
} from '@mui/material';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 50,
    width:"100%",
    display: 'flex',
    '@media (min-width: 600px)': {
        paddingLeft: "5px",
        paddingRight:"5px"
    },
}));


const abc = styled(TextField)(({ theme }) => ({
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
        // Default left padding is 6px
        padding: 0,
    },
}));

// ----------------------------------------------------------------------

UserDatePicker.propTypes = {
    numSelected: PropTypes.number,
    filter: PropTypes.instanceOf(Date),
    onFilter: PropTypes.func,
    filterPlaceHolder: PropTypes.string
};

export default function UserDatePicker({ numSelected, filter, onFilter, filterPlaceHolder }) {
    return (
        <RootStyle
            sx={{
                ...(numSelected > 0 && {
                color: 'primary.main',
                bgcolor: 'primary.lighter',
                })
            }}
        >
            <LocalizationProvider dateAdapter={AdapterDateFns} sx={{padding:"0px"}}>
                <DatePicker
                    size="small"
                    inputFormat="dd/MM/yyyy"
                    value={filter}
                    onChange={onFilter}
                    label={filterPlaceHolder}
                    renderInput={(params) => <TextField 
                        size="small" 
                        {...params}
                    />
                    }
                />
            </LocalizationProvider>
        </RootStyle>
    );
}
