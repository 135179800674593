import { USER_VERIFICATION_DETAIL, RESET_USER_DETAIL } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case USER_VERIFICATION_DETAIL:
            return { ...state, ..._.mapKeys(action.payload,'id') }
        case RESET_USER_DETAIL:
            return {}
        default: return state;
    }
}