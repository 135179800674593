import { DELETE_ALERTS, ADD_ALERT } from "../actions/types";
import _ from "lodash";

export default (state = {}, action) => {
  switch(action.type){
    // case ALL_ALERTS:
    //   return {...state, ...action.payload}
    case ADD_ALERT:
      return {...state, ...action.payload}
    case DELETE_ALERTS:
      return {};
    default: return state;
  }
}