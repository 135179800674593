import { 
    ADD_ALERT,
    FETCH_ALL_TRANSACTIONS,
} from './types';
import shortId from "shortid";
import axios from 'axios';

export const fetchAllTransactions= (pageNumber) => async dispatch => {
    if(pageNumber >= 2) {
        await axios({
            method: "post",
            url: `https://irhere.com.au/api/get_transaction_history/page/${pageNumber}`,
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
        }).then(response => {
            dispatch({
                type:FETCH_ALL_TRANSACTIONS, 
                payload:response.data
            });
        }).catch(error => {
        });
    } else {
        await axios({
            method: "post",
            url: "https://irhere.com.au/api/get_transaction_history",
            headers: { 
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "*",
                mode: "cors",
            },
        }).then(response => {
            dispatch({
                type:FETCH_ALL_TRANSACTIONS, 
                payload:response.data
            });
        }).catch(error => {
        });
    }
}