import React from 'react';
import './generalcert.css';
import moment from 'moment';
import { useState, useEffect } from 'react';

export default function Download(props) {
    const [size, setSize] = useState({});
    const [downloadImages, setDownloadImages] = useState([]);
    const [checkImages, setCheckedImages] = useState([]);
    const [imgIndexes, setImgIndexes] = useState([]);
    const [src, setSrc] = useState('');


    useEffect(() => {
        if(!_.isEmpty(props.images)) {
            setDownloadImages(props.images);
        }
    },[props.images]);

    useEffect(() => {
        if(!_.isEmpty(props.cert)) {
            const qr = props.cert.qr;
            setSrc(qr);
        }
    }, [props.cert])

    useEffect(() => {
        if(!_.isEmpty(props.checked)) {
            setCheckedImages(props.checked);
            var img_index = Object.keys(props.checked).filter(item => props.checked[item] == true);
            setImgIndexes(img_index);
        }
    },[props.checked]);

    return (
        <>
            <div className='modalDownload'>
                <div style={{position:"relative"}}>
                    <img src={!_.isEmpty(props.images) && props.images.img} alt="img" onLoad={event => {
                        setSize({
                            height: event.target.naturalHeight,
                            width: event.target.naturalWidth
                        });
                        
                    }} />
                    <div>
                        <div className={!_.isEmpty(size) && size.height < size.width ? 'modalCenteredDownload bottom' : 'modalCenteredDownload top'}>
                            <div style={{padding:"15px",display:"flex"}}>
                                <div style={{width:"85%", fontSize: "13px", fontWeight: "500"}}>
                                    <div>
                                        <div>
                                            <b>Date:</b> <span>{moment(!_.isEmpty(props.images) && props.images.date).format("D MMM, YYYY h:mm A")}</span>
                                        </div>
                                        <div>
                                            <b>Location:</b> <span style={{wordBreak: "break-word"}}>{!_.isEmpty(props.images) && props.images.location}</span>
                                        </div>
                                    </div>
                                </div>
                                <div style={{width:"15%", display:"flex",marginLeft:"10px"}}>
                                    <img  alt='ava' src={`data:image/jpeg;base64,${src}`} style={{width:"100%",height:"100%",margin:"auto"}} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
