

import { ADD_ALERT, DELETE_ALERTS } from "./types";

export const addAlert = (message) => dispatch => {
  dispatch({
    type: ADD_ALERT,
    payload:message
  });
}

export const removeAlerts = () => dispatch => {
  dispatch({
    type: DELETE_ALERTS,
  });
}