import {
  SIGN_IN,
} from "../actions/types";

const INITIAL_STATES = {
  isSignedIn: false,
  user:{}
};

export default (state = INITIAL_STATES, action) => {
  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        isSignedIn: true,
        user:action.payload
      };
    default:
      return state;
  }
};
