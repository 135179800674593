import axios from 'axios';
import shortId from 'shortid';
import { FETCH_ALL_VOUCHERS, CREATE_VOUCHER, ADD_ALERT } from './types';
import moment from 'moment';
export const fetchVouchersList= (bodyFormData,pageNumber) => async dispatch => {
    await axios({
        method: "post",
        url: `https://irhere.com.au/api/get_voucher`,
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
    }).then(response => {
        dispatch({
            type:FETCH_ALL_VOUCHERS, 
            payload:response.data
        });
    }).catch(error => {
        console.log(error)
    });
}

export const createVoucher = (bodyFormData) => async dispatch => {
    await axios({
        method: "post",
        url: 'https://irhere.com.au/api/create_voucher',
        headers: { 
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
            mode: "cors",
        },
        data: bodyFormData,
    }).then(response => {
        let obj = {...response.data,date:moment(new Date().getTime()).format("YYYY-MM-DD h:mm:ss")}
        dispatch({
            type:CREATE_VOUCHER, 
            payload:obj
        });
        dispatch({
            type: ADD_ALERT,
            payload: {
                key: shortId.generate(),
                status: 'success',
                color:'info',
                message: response.data.desc,
                errors: {}
            },
        });
    }).catch(error => {
        console.log(error)
    });
}