import { FETCH_ALL_VOUCHERS, CREATE_VOUCHER } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {data: {},paginate:{}}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_ALL_VOUCHERS:
            return {...state, data:_.mapKeys(action.payload,'voucher_code')}
        case CREATE_VOUCHER:
            return { ...state,
                data: { ...state.data ,[action.payload.voucher_code]:action.payload
                }
            }
        default: return state;
    }
}