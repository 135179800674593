import { 
  SIGN_IN,
  ADD_ALERT,
  LOG_OUT
} from './types';
import shortId from "shortid";
import axios from 'axios';


export const signIn = (values) => dispatch => {
    var config = {
    headers: {
      "Content-Type": "multipart/form-data"
    },
  };
  const data = new FormData();
  data.append('email', values.email);
  data.append('password', values.password);
  axios
    .post("https://irhere.com.au/api/login_admin", data, config)
    .then(function (response) {
      if(response.data.code == "1") {
        sessionStorage.setItem("isSignedIn", true);
        sessionStorage.setItem("user", JSON.stringify(response.data));
        dispatch({
          type: SIGN_IN,
          payload: response.data
        });
      } else if(response.data.code == "0") {
        dispatch({
          type: ADD_ALERT,
          payload: {
            key: shortId.generate(),
            status: 'error',
            color:'error',
            message: response.data.desc,
            errors: {}
          },
        });
      }
    })
    .catch(function (error) {
      dispatch({
        type: ADD_ALERT,
        payload: {
          key: shortId.generate(),
          status: 'error',
          color:'error',
          message: error,
          errors: {}
        },
      });
    });
}

export const logOut = () => dispatch => {
    dispatch({
        type: LOG_OUT,
    });
}