import { FETCH_ALL_ADMINS, UPDATE_ADMIN_DETAILS, DELETE_ADMIN } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {data: {},paginate:{}}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_ALL_ADMINS:
            return {...state, data:_.mapKeys(action.payload, 'id')}
        case UPDATE_ADMIN_DETAILS:
            return { ...state,
                data: { ...state.data ,[action.payload.payload.id]:action.payload.payload
                    }
            }
        case DELETE_ADMIN:
            return {...state, data:_.omit(state.data, action.payload)};
        default: return state;
    }
}