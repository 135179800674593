import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from 'react-redux';
import moment from 'moment';
import Label from '../components/Label';
import { sentenceCase } from 'change-case';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IconButton from '@mui/material/IconButton';
import CloudDownload from "@material-ui/icons/CloudDownload";
import './generalcert.css';
import CloseOutlined from "@material-ui/icons/CloseOutlined";
import ArrowForwardIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIos';
import Masonry from '@mui/lab/Masonry';
import html2canvas from 'html2canvas';
import JSZip from "jszip";
import { saveAs } from "file-saver";
// material
import {
Card,
FormGroup,
FormControlLabel,
Checkbox,
Grid,
Table,
Box,
TableRow,
TableBody,
TableCell,
Typography,
Button,
Skeleton
} from '@mui/material';
import { useState, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import { useLocation } from 'react-router-dom';
// components
import Page from '../components/Page';
import Download from "./Download";
import _ from "lodash";
import axios from "axios";
import LoopIcon from '@mui/icons-material/Loop';

const useStyles = makeStyles({
    root: {
        minWidth: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    card: {
        maxWidth: "100%",
        width:"auto",
        minHeight: "100%",
        display: "flex",
        alignItems: "center",
        padding:"20px",
        textAlign:"left",
        boxShadow:"none"
    }
});

function GeneralCert(props) {
    const classes = useStyles();
    const [src, setSrc] = useState('');
    const [verId, setVerId] = useState('');
    const [verDate, setVerDate] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [quarantinDays, setQurantinDays] = useState('');
    const [status, setStatus] = useState('');
    const [trueStatus, setTrueStatus] = useState('');
    const [verDesc, setVerDesc] = useState('');
    const [initiatorName, setInitiatorName] = useState('');
    const [ url, setUrl ] = useState('');
    const [ images, setImages ] = useState([]);
    const [ index, setIndex ] = useState('');
    const [ modal, setModal ] = useState(false);
    const location = useLocation();
    const [size, setSize] = useState({});
    const [checkedAll, setCheckedAll] = useState(false);
    const [checked, setChecked] = useState([]);
    const [withTime, setWithTime] = useState(false);
    const [certData, setCertData] = useState([]);
    const [loading, setLoading] = useState(false)
    const isFirstRun = useRef(true);
    const isSecondRun = useRef(true);

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        const qr = props.view.qr;
        const date = props.view.date;
        const finalDate = moment(date).format("D MMM, YYYY h:mm A");
        setSrc(qr);
        setVerId(props.view.id);
        setVerDate(finalDate);
        setName(props.view.name || props.view.receiver_name);
        setEmail(props.view.email);
        setAddress(props.view.address);
        setQurantinDays(props.view.quarantine_days);
        setStatus(props.view.status);
        setTrueStatus(props.view.true_status);
        setVerDesc(props.view.desc);
        setInitiatorName(props.view.initiator_name);
        setCertData(props.view);
    }, [props.view]);

    useEffect(() => {
        if (isSecondRun.current) {
            isSecondRun.current = false;
            return;
        }
        if(!_.isEmpty(props.certImages.data)) {
            let data = Object.values(props.certImages.data);
            var a = [];
            a = data.map((i, index) => {
                return {
                    img: "data:image/jpeg;base64,"+i.thumbnail_base64,
                    title:i.caption,
                    date:i.image_date,
                    location:i.address,
                    id:i.image_id,
                    check_box_no:index,
                    lat:i.lat,
                    long:i.long,
                }
            });
            setImages(a);
            const dataArray = a.map((i) => i.check_box_no);
            const initialState = dataArray.reduce((o, key) => ({ ...o, [key]: false}), {});
            setChecked(initialState);
        }
    },[props.certImages]);

    const getSingleImage = async (id, i) => {
      try {
        if (images[i].isHighQuality) {
          console.log('High-quality image already loaded');
          return;
        }
        setLoading(true);
        const data = new FormData();
        data.append('image_id', id);
        const response = await axios.post('https://irhere.com.au/api/get_image_from_id', data);
        const highQualityImage = response.data[0]?.image_base64;
        setImages(prevImages => {
          const newImages = [...prevImages];
          newImages[i].img = `data:image/gif;base64,${highQualityImage}`;
          newImages[i].isHighQuality = true;
          return newImages;
        });
      } catch (error) {
         console.log(error);
      }
      finally {
        setLoading(false);
      }
    };

    const renderStatus = () => {
        const status_value = status;
        const true_status = trueStatus;
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() =="true") {
            return <Box sx={{textAlign:"center", margin:"0px 0px 10px 10px"}}>
                <Label
                    variant="ghost"
                    sx={{backgroundColor:"#01915f", padding:"14px",borderRadius:"30px"}}
                >
                    <span style={{fontSize:"15px", color:"white"}}>{sentenceCase("verified")}</span>
                </Label>
                
            </Box>
        } 
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() == "false") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </Box>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "true") {
            return <>
                <Label
                    variant="ghost"
                    color={'warning'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("pending")}
                </Label>
            </>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "false") {
            return <>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </>
        }
    }

    const closeButton = () => {
        setUrl('');
        setModal(false);
    }

    const renderSuccesOrFailed = () => {
        const status_value = status;
        const true_status = trueStatus;
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() == "true") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    sx={{backgroundColor:"white", padding:"14px", border:"1px solid #01915f", borderRadius:"30px"}}
                >
                    <span style={{color:"#01915f"}}><TaskAltIcon /></span><span style={{fontSize:"17px", color:"#01915f"}}>{sentenceCase("Success")}</span>
                </Label>
                
            </Box>
        } 
        if(status_value.toLowerCase() == "true" && true_status.toLowerCase() == "false") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </Box>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "true") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'warning'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("pending")}
                </Label>
            </Box>
        } 
        if(status_value.toLowerCase() == "false" && true_status.toLowerCase() == "false") {
            return <Box sx={{textAlign:"center"}}>
                <Label
                    variant="ghost"
                    color={'error'}
                    sx={{padding:"14px"}}
                >
                    {sentenceCase("unverified")}
                </Label>
            </Box>
        }
    }

    const openModal = (index) => {
        setUrl(images[index]);
        setIndex(index);
        setModal(true);
    }

    var zip = JSZip();

    const dataURltoBlob = ( dataURl ) => {
        var byteString = atob(dataURl.split(',')[1]);

        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for(var i=0; i< byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        var bb = new Blob([ab]);
        return bb;
    }

    const download = () => {
        zip.generateAsync({ type: "blob" }).then(function(blob) {
            saveAs(blob, "images.zip");
        });
    };

    const generateZip = (arr) => {
        if (arr.length > 0) {
            arr.map((a) => {
                var image = dataURltoBlob(a.img);
                zip.file(`images/image_${a.index}.png`, image, {
                    binary: true
                });
            })
            download();
        }
    };

    const handleDownload = () => {
        let arr = [];
        let img_index = Object.keys(checked).filter(item => checked[item] == true);
        if(withTime) {
            var numberOfImages = img_index.length;
            img_index.map((i,index) => {
                const input = document.getElementById(`downloadablediv${i}`);
                html2canvas(input,
                    {
                        logging:true, 
                        useCORS:true,
                        onclone: function (clonedDoc) {
                            clonedDoc.getElementById(`downloadablediv${i}`).style.display = 'block';
                        }
                    }).then((canvas) =>  {
                        var imgageData = canvas.toDataURL("image/png");
                        arr[i] = {index:i,img:imgageData};
                        // arr.push({index:i,img:imgageData});
                        if(index+1 == numberOfImages) {
                            generateZip(arr);
                        }
                });
            });
        } else {
            img_index.map((i) => {
                arr[i] = {index:i,img:images[i].img};
            });
            generateZip(arr);
        }
    };

    const onMoveNextRequest = (index) => {
        const modifiedIndex = (index+1)% images.length;
        const imageId = images[modifiedIndex].id
        setIndex(modifiedIndex);
        getSingleImage(imageId, modifiedIndex)
    }

    const onMovePrevRequest = (index) => {
        const modifiedIndex = (index+ images.length - 1)% images.length;
        const imageId = images[modifiedIndex].id
        setIndex(modifiedIndex);
        getSingleImage(imageId, modifiedIndex)
    }

    const toggleCheck = (inputName) => {
        setChecked((prevState) => {
            const newState = { ...prevState };
            newState[inputName] = !prevState[inputName];
            return newState;
        });
    };

    const timeToggleCheck = (input) => {
        setWithTime((prevState) => {
            const newState = { ...prevState };
            newState[input] = !prevState[input];
            return newState;
        });
    }

    const selectAll = (value) => {
        setCheckedAll(value);
        setChecked((prevState) => {
            const newState = { ...prevState };
            for (const inputName in newState) {
                newState[inputName] = value;
            }
            return newState;
        });
    };

    const renderImages = () => {
        if(!_.isEmpty(props.certImages.data)) {
            let data = Object.values(props.certImages.data);
            return <>
                <TableRow>
                    <Box sx={{padding:"16px"}}>
                        <Masonry columns={3} className='gridcontainer'>
                            {data.map((i, index) => (
                                <Grid item key={index}>
                                    <input id="checkboxid" name={index} value={index} type="checkbox" class="css-checkbox" onChange={() => toggleCheck(index)} checked={checked[index]} />
                                    <Grid onClick={() => {
                                        openModal(index);
                                        getSingleImage(i.image_id, index);
                                    }} className="container">
                                        <img src={`data:image/jpeg;base64,${i.thumbnail_base64}`} style={{cursor:"pointer", width: "60px", height: "auto"}} alt="Avatar" className='image' />
                                        <div className="middle">
                                            <div style={{fontSize:"14px"}}>Preview Image</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </Masonry>
                    </Box>
                </TableRow>
            </>
        } else {
            return <>
                <TableRow>
                    <Grid container className='gridcontainer'>
                        <p style={{margin:"auto",paddingTop:"10px"}}>No Images Attached Yet</p>
                    </Grid>
                </TableRow>
            </>
        }
    }

    <>
      <style>
        {`
          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
      {/* Rest of your component code */}
    </>

    return (
      <>
        <style>
          {`
            @keyframes spin {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          `}
        </style>
        <Page title="validation certificate" sx={{display:"block"}}>
            <Grid
                container
                className={classes.root}
                spacing={0}
                alignItems="center"
                justify="center"
            >
                <Card className={classes.card}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{padding:"0px",borderBottom:"0px"}}>
                                    <Typography variant="h3" sx={{padding:"0px", alignItems:"center", textAlign:"center", color:'#5C81E4' }}>
                                        <u>Validation Certificate</u>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{borderBottom:"0px"}}>
                                    {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"60px"}} /> : (
                                        <Box>
                                            {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderSuccesOrFailed()}
                                        </Box>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{borderBottom:"2px dotted #5C81E4"}}>
                                <TableCell>
                                    {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"20px"}} /> : (
                                        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center",marginBottom:"5px"}}>
                                            <Typography variant="body1" sx={{display:"flex", padding:"0px 10px 0px 15px"}}>
                                            <span style={{fontSize:"19px"}}>Address:</span> {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderStatus()}
                                            </Typography>
                                        </Box>
                                    )}
                                    {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"100px"}} /> : (
                                        <Box sx={{display:"flex", alignItems:"center", justifyContent:"center",marginBottom:"5px"}}>
                                            <Typography variant="body1" sx={{display:"flex", padding:"0px 10px 0px 15px"}}>
                                                <span style={{fontSize:"19px"}}>Identity:</span> {!_.isEmpty(status) && !_.isEmpty(trueStatus) && renderStatus()}
                                            </Typography>
                                        </Box>
                                    )}
                                    {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"20px"}} /> : (
                                        <Box sx={{marginBottom:"5px"}}>
                                            <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                                This Certifies that <br />
                                            </Typography>
                                            <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                                <b>{name}</b>
                                            </Typography>
                                        </Box>
                                    )}
                                    {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"20px"}} /> : (
                                        <Box sx={{marginBottom:"5px"}}>
                                            <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                                was located at
                                            </Typography>
                                            <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                                <b>{address}</b>
                                            </Typography>
                                        </Box>
                                    )}
                                    {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"20px"}} /> : (
                                        <Box sx={{marginBottom:"5px"}}>
                                            <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center" }}>
                                                on <br />
                                            </Typography>
                                            <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center" }}>
                                                <b>{verDate}</b>
                                            </Typography>
                                        </Box>
                                    )}
                                    {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"20px"}} /> : (
                                        <Box sx={{marginBottom:"8px"}}>
                                            { location.pathname == '/viewgeneralcert' && 
                                                <>
                                                <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center"}}>
                                                    Initiated By <br />
                                                </Typography>
                                                <Typography variant="body1" sx={{padding:"0px 0px 0px 15px", fontSize:"18px", textAlign:"center" }}>
                                                    <b>{initiatorName}</b>
                                                </Typography>
                                                </>
                                            }
                                        </Box>
                                    )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{border:"0px"}}>
                                {_.isEmpty(certData) ? <Skeleton variant="rectangular" sx={{width:"100%",height:"60px"}} /> : (
                                    <Box alignItems="center" component="img" src={`data:image/jpeg;base64,${src}`} sx={{ display:"block", marginLeft:"auto", marginRight:"auto", width:"32%" }} /> 
                                )}
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{border:"0px", display:"flex",textAlign:"center",alignItems:"center",justifyContent:"center"}}>
                                    <CheckCircleIcon sx={{color:"#01915f",padding:"3px 3px 3px 3px"}} />
                                    <Typography variant="body1">
                                        Validated by IRhere using V+V Technology
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Card>
                <Card Card className={classes.card}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{border:"0px"}}>
                                    <Typography variant="h3" sx={{padding:"0px", alignItems:"center", textAlign:"center", color:'#5C81E4', justifyContent:"center" }}>
                                        <u>Validation Images</u>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                            {props.certImages.code == 200 ? renderImages() : <Skeleton variant="rectangular" sx={{width:"100%",height:"200px"}} /> }
                        </TableBody>
                    </Table>
                </Card>
                {
                    Object.values(checked).includes(true) &&
                        <Box>
                            <FormGroup sx={{fontSize:"0px"}}>
                                <FormControlLabel control={<Checkbox size="small" onChange={(event) => selectAll(event.target.checked)} checked={checkedAll} />} label="Select All" />
                                <FormControlLabel control={<Checkbox size="small" onChange={(event) => setWithTime(!withTime)} checked={withTime} />} label="Timestamps & Notes" />
                                <Button sx={{float:"left",backgroundColor:"#5C81E4",marginTop:"10px",marginBottom:"10px"}} onClick={()=> handleDownload()} variant="contained" startIcon={<CloudDownload />}>
                                    Download
                                </Button>
                            </FormGroup>
                        </Box>
                }
                {
                    !_.isEmpty(checked) && withTime &&
                    Object.keys(checked).filter(item => checked[item] == true).map((i) => {
                        return <div id={`downloadablediv${i}`} style={{display:"none"}}>
                            <Download images={images[i]} checked={checked} cert={props.view}  />
                        </div>
                    })
                }
            {
                modal && 
                    <Modal
                        open={modal}
                        onClose={closeButton}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <>
                            <div className="modalContainer">
                                <img src={images[index].img} alt="Avatar" onLoad={event => {
                                        setSize({
                                            height: event.target.naturalHeight,
                                            width: event.target.naturalWidth
                                        });
                                        
                                    }} className={!_.isEmpty(size) && size.height < size.width ? 'modalImage' : 'modalImages'}  />
                                <div>
                                    <div className="modalCentered">
                                        <div style={{padding:"15px 22px 15px 22px",display:"flex"}}>
                                            <div style={{width:"85%", fontSize: "13px", fontWeight: "500"}}>
                                                <div>
                                                    <div>
                                                        <b>Date:</b> <span>{moment(images[index].date).format("D MMM, YYYY h:mm A")}</span>
                                                    </div>
                                                    <div>
                                                        <b>Location:</b> <span style={{wordBreak: "break-word"}}>{images[index].location}</span>
                                                    </div>
                                                    <div>
                                                        <b>Lat/Long (within 1m): </b><span>{images[index]?.lat?.slice(0, 7) + " / " + images[index]?.long?.slice(0, 7)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{width:"15%", display:"flex",marginLeft:"10px"}}>
                                                <img  alt='ava' src={`data:image/jpeg;base64,${src}`} style={{width:"49px",height:"49px",margin:"auto"}} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="fowardarrowCentered">
                                    <div style={{float:"right"}}>
                                        <IconButton sx={{color:"white"}} aria-label="upload picture" component="span" onClick={() => onMoveNextRequest(index)}>
                                            <ArrowForwardIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <div style={{position:"absolute",top:"50%"}}>
                                        <IconButton sx={{color:"white"}} aria-label="upload picture" component="span" onClick={() => onMovePrevRequest(index)}>
                                            <ArrowBackIcon />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                    <div style={{position:"absolute",top:"0%"}}>
                                        <IconButton sx={{color:"white"}} aria-label="upload picture" component="span" onClick={() => closeButton()}>
                                            <CloseOutlined />
                                        </IconButton>
                                    </div>
                                </div>
                                <div>
                                {loading && <div style={{position:"absolute", top:"50%", left: "50%", transform: "translate(-50%, -50%)"}}>
                                  <LoopIcon style={{ color: "white", fontSize: '50px', animation: "spin 1s linear infinite" }} />
                                </div>}
                                </div>
                            </div>
                        </>
                    </Modal>
                }
            </Grid>
        </Page>
      </>
    )
}

const mapStateToProps = state => {
    return {
        view: state.viewCert,
        certImages: state.certImages
    }
}

export default connect(mapStateToProps, {  })(GeneralCert);


