import React, { Component } from 'react'
import { useEffect } from 'react';
import { sentenceCase } from 'change-case';
import {
    Card,
    Grid,
    Table,
    Stack,
    Box,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    IconButton,
    Container,
    TableContainer

} from '@mui/material';
import Page from '../components/Page';
import _ from 'lodash';
import { connect } from 'react-redux';
import { UserListHead } from '../components/_dashboard/user';
import moment from 'moment';
import Label from '../components/Label';
import { Link as RouterLink } from 'react-router-dom';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import { useNavigate } from 'react-router-dom';

const TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'emailphone', label: 'Email/Phone', alignRight: false },
    { id: 'street', label: 'Street', alignRight: false },
    { id: 'subrub', label: 'Suburb', alignRight: false },
    { id: 'state', label: 'State', alignRight: false },
    { id: 'postcode', label: 'Post Code', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false },
    { id: 'quarantineday', label: 'Quarantine Day', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false }
];

const SEC_TABLE_HEAD = [
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'date', label: 'Date', alignRight: false }
];

function VerificationDetails(props) {
    const navigate = useNavigate();
    const showQuarantineDays = (u) => {
        const date = new Date(u.date);
        const newdate = new Date();
        var msDiff = Math.abs(newdate.getTime() - date.getTime()); 
        var rdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
        if(rdays == u.quarantine_days) {
                return `${rdays} (last day)`+"/"+u.quarantine_days;
            } else if(rdays > u.quarantine_days) {
                return u.quarantine_days+"/"+u.quarantine_days+"(completed)";
            } else {
                return (rdays+1)+"/"+u.quarantine_days;
            }
    }
    
    const renderQuarantineDays = (u) => {
        if(!_.isEmpty(props.users)) {
            const user = props.users.find(user => user.email == u.email);
            if(!_.isEmpty(user)) {
                if(user.email == u.email) {
                    return showQuarantineDays(user);
                }
            }
        }
    }
    
    const showStreet = (user) => {
        if(user.address.toLowerCase() != 'n/a') {
            const street = user.address;
            const str = street.split(' ');
            const final = str[0]+" "+str[1]+" "+str[2];
            const finalStreet = final.replace(','," ");
            if(finalStreet == "undefined undefined undefined") {
                return " ";
            } else {
                return finalStreet;
            }
        } else {
            return "-";
        }
    }
    
    const showSuburb = (user) => {
        if(user.address.toLowerCase() != 'n/a') {
            const street = user.address;
            const str = street.split(' ');
            return str[3] || " ";
        } else {
            return "-";
        }
    }
    
    const showState = (user) => {
        if(user.address.toLowerCase() != 'n/a') {
            const street = user.address;
            const str = street.split(' ');
            return str[4] || " ";
        } else {
            return "-"
        }
    }
    
    const showPostCode = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
        const street = user.address;
        const str = street.split(' ');
        const postCode = str[5]+" ";
        const finalPostCode = postCode.replace(','," ");
        if(finalPostCode == "undefined ") {
            return " ";
            } else {
            return finalPostCode;
            }
        } else {
            return "-";
        }
    }
    
    const renderStatus = (u) => {
        const status = u.status;
        const true_status = u.true_status;
        if(status.toLowerCase() == "true" && true_status.toLowerCase() == "true") {
            return <>
                <Label
                variant="ghost"
                color={'success'}
                >
                {sentenceCase("verified")}
                </Label>
            </>
        } 
        if(status.toLowerCase() == "true" && true_status.toLowerCase() == "false") {
            return <>
                <Label
                variant="ghost"
                color={'error'}
                >
                {sentenceCase("unverified")}
                </Label>
            </>
        } 
        if(status.toLowerCase() == "false" && true_status.toLowerCase() == "true") {
            return <>
                <Label
                variant="ghost"
                color={'warning'}
                >
                {sentenceCase("pending")}
                </Label>
            </>
        } 
        if(status.toLowerCase() == "false" && true_status.toLowerCase() == "false") {
            return <>
                <Label
                variant="ghost"
                color={'error'}
                >
                {sentenceCase("unverified")}
                </Label>
            </>
        }
    }

    const handleButtonClick = () => {
        navigate(`/viewcert?id=${props.user.id}`, { replace: true });
    }
    
    const renderUserDetail = (user) => {
        if(!_.isEmpty(user)) {
            return (
                <TableRow
                    hover
                    key={user.id}
                >
                    <TableCell align="left" style={{padding:"10px"}}>{user.id}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{user.name}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{user.email}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{showStreet(user)}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{showSuburb(user)}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{showState(user)}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{showPostCode(user)}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{moment(user.date).format("D MMM, YYYY h:mm A")}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{renderQuarantineDays(user)}</TableCell>
                    <TableCell align="left" style={{padding:"10px"}}>{renderStatus(user)}</TableCell>
                </TableRow>
            );
        }
    }
    
    const renderList = (user) => {
        if(!_.isEmpty(user.verification_history)) {
            return user.verification_history.map((obj) => {
                return  <TableRow id={obj.date}>
                <TableCell>
                    {obj.desc}
                </TableCell>
                <TableCell>
                    {moment(obj.date).format("D MMM, YYYY h:mm A")}
                </TableCell>
            </TableRow>
            })
        }
    }
    return (
        <Page title="UserDetails">
            <Container maxWidth="100%">
                <Box sx={{paddingY:"15px",}}>
                    <Typography>
                        <b>User Details</b>
                        <IconButton onClick={handleButtonClick} sx={{float:"right"}}>
                            <DocumentScannerOutlinedIcon />
                            <span style={{fontSize:"13px"}}>view certificate</span>
                        </IconButton>
                    </Typography>
                </Box>
                <Card sx={{width:"100%"}}>
                    <TableContainer sx={{ minWidth: 800}}>
                        <Table>
                            <UserListHead
                                headLabel={TABLE_HEAD}
                            />
                            <TableBody>
                                {renderUserDetail(props.user)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card>
                <Box sx={{paddingY:"15px"}}>
                    <Typography>
                        <b>Validations History</b>
                    </Typography>
                </Box>
                { !_.isEmpty(props.user.verification_history) ? (
                    <Card sx={{width:"100%", marginBottom:"15px"}}>
                    <TableContainer sx={{ minWidth: 800}}>
                        <Table>
                            <UserListHead
                                headLabel={SEC_TABLE_HEAD}
                            />
                            <TableBody>
                                {renderList(props.user)}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Card> ) : <Card sx={{width:"100%", marginBottom:"15px"}}>No Validation History Found</Card> }
            </Container>
        </Page>
    );
}

const mapStateToProps = state => {
    return {
        verificationDetails: state.verificationDetails,
        users:Object.values(state.users),
    }
}

export default connect(mapStateToProps, {  })(VerificationDetails);

