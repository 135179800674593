import { FETCH_ALL_USERS, SEARCH_USER } from "../actions/types";
import _ from 'lodash';
const INITIAL_STATE = {data: {},paginate:{}}

export default (state=INITIAL_STATE, action) => {
    switch(action.type){
        case FETCH_ALL_USERS:
            return {...state, data:_.mapKeys(action.payload.Data, 'id'), paginate:action.payload.Page}
        case SEARCH_USER:
            return {...state, data:_.mapKeys(action.payload.Data, 'id'), paginate:action.payload.Page}
        default: return state;
    }
}