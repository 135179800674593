import { Icon } from '@iconify/react';
import { Navigate, useRoutes } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import editFill from '@iconify/icons-eva/edit-fill';
import { Link as RouterLink } from 'react-router-dom';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import bell from '@iconify/icons-eva/bell-outline';
import activity from '@iconify/icons-eva/activity-outline';
import reset from '@iconify/icons-eva/person-remove-outline';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText, Modal, Box, Typography } from '@mui/material';
import { notifyUser, setUserVerTime, resetUser, sendNotification } from '../../../actions/userListAction';
import { connect } from 'react-redux';


// ----------------------------------------------------------------------

UserMoreMenu.propTypes = {
  userObject: PropTypes.object,
  isTrue:PropTypes.bool,
  handleClick:PropTypes.func
};

function UserMoreMenu(props) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>
      {
        props.isTrue && 
        <Menu
          open={isOpen}
          anchorEl={ref.current}
          onClose={() => setIsOpen(false)}
          PaperProps={{
            sx: { width: 200, maxWidth: '100%' }
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={bell} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="notify" primaryTypographyProps={{ variant: 'body2' }} onClick={() => {
            var bodyFormData = new FormData();
            bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
            bodyFormData.append('user_id', props.userObject.user_id);
            props.sendNotification(bodyFormData);
            props.notifyUser(props.userObject.fcm_token);
            props.setUserVerTime(bodyFormData);
          }}/>
        </MenuItem>

        <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={reset} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="reset user" primaryTypographyProps={{ variant: 'body2' }} onClick={() => {
            var bodyFormData = new FormData();
            bodyFormData.append('apikey', '68685dc6-5fb7-46c6-8cd5-228fc33b5485');
            bodyFormData.append('user_id', props.userObject.user_id);
            props.resetUser(bodyFormData);
          }}/>
        </MenuItem>
        <MenuItem sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={activity} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="see user detail" primaryTypographyProps={{ variant: 'body2' }} onClick={() => {props.handleClick();setIsOpen(false);}}/>
        </MenuItem>
        </Menu>
      }
      {
        !props.isTrue && 
          <Menu
            open={isOpen}
            anchorEl={ref.current}
            onClose={() => setIsOpen(false)}
            PaperProps={{
              sx: { width: 200, maxWidth: '100%' }
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem sx={{ color: 'text.secondary' }}>
              <ListItemIcon>
                <Icon icon={activity} width={24} height={24} />
              </ListItemIcon>
              <ListItemText primary="see detail" primaryTypographyProps={{ variant: 'body2' }}  onClick={() => {props.handleClick();setIsOpen(false);}}/>
            </MenuItem>
          </Menu>
      }
    </>
  );
}
export default connect(null, { notifyUser, setUserVerTime, resetUser, sendNotification })(UserMoreMenu);
