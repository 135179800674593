import { sentenceCase } from 'change-case';
import { useState } from 'react';
import React from 'react'
// material
import {
Card,
Table,
Box,
TableRow,
TableBody,
TableCell,
Container,
Typography,
TableContainer,
TablePagination,
} from '@mui/material';
import { connect } from 'react-redux';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import { UserListHead } from '../components/_dashboard/user';
import { fetchUserDetails } from '../actions/userDetailsAction';
import { resetState } from '../actions/resetAction';
import { RESET_USER_DETAIL } from "../actions/types";
import _ from 'lodash';
import moment from 'moment';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'emailphone', label: 'Email/Phone', alignRight: false },
  { id: 'street', label: 'Street', alignRight: false },
  { id: 'suburb', label: 'Suburb', alignRight: false },
  { id: 'state', label: 'State', alignRight: false },
  { id: 'postcode', label: 'Post Code', alignRight: false },
  { id: 'quarantineday', label: 'Quarantine Day', alignRight: false },
  { id: 'action', label: '', alignRight: false },
];

const SEC_TABLE_HEAD = [
    { id: 'id', label: 'ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'emailphone', label: 'Email/Phone', alignRight: false },
    { id: 'street', label: 'Street', alignRight: false },
    { id: 'suburb', label: 'Subrub', alignRight: false },
    { id: 'state', label: 'State', alignRight: false },
    { id: 'postcode', label: 'Post Code', alignRight: false },
    { id: 'quarantineday', label: 'Quarantine Day', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
];

// ----------------------------------------------------------------------

function UserDetails(props) {
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const applyFilter = (array) => {
    const sorted_array= array.sort(function(a,b){
      return new Date(b.date).getTime() - new Date(a.date).getTime() ;
    });
    const stabilizedThis = sorted_array.map((el, index) => ([el, index]));
    return stabilizedThis.map((el) => el[0]);
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const showQuarantineDays = (u) => {
    const date = new Date(u.date);
    const newdate = new Date();
    var msDiff = Math.abs(newdate.getTime() - date.getTime()); 
    var rdays = Math.floor(msDiff / (1000 * 60 * 60 * 24));
    if(rdays == u.quarantine_days) {
      return `${rdays} (last day)`+"/"+u.quarantine_days;
    } else if(rdays > u.quarantine_days) {
      return u.quarantine_days+"/"+u.quarantine_days+"(completed)";
    } else {
      return (rdays+1)+"/"+u.quarantine_days;
    }
  }

  const showStreet = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      const final = str[0]+" "+str[1]+" "+str[2];
      const finalStreet = final.replace(','," ");
      if(finalStreet == "undefined undefined undefined") {
        return " ";
      } else {
        return finalStreet;
      }
    } else {
      return "-";
    }
  }

  const showSuburb = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      return str[3] || " ";
    } else {
      return "-";
    }
  }

  const showState = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      return str[4] || " ";
    } else {
      return "-"
    }
  }

  const showPostCode = (user) => {
    if(user.address.toLowerCase() != 'n/a') {
      const street = user.address;
      const str = street.split(' ');
      const postCode = str[5]+" ";
      const finalPostCode = postCode.replace(','," ");
      if(finalPostCode == "undefined ") {
        return " ";
      } else {
        return finalPostCode;
      }
    } else {
      return "-";
    }
  }

  const renderDesc = (user) => {
    if(user.desc.toLowerCase() != 'none') {
      return user.desc;
    } else {
      return "-";
    }
  }

  const renderStatus = (u) => {
    const status = u.status;
    const true_status = u.true_status;
    if(status.toLowerCase() == "true" && true_status.toLowerCase() == "true") {
        return <>
          <Label
            variant="ghost"
            color={'success'}
          >
            {sentenceCase("verified")}
          </Label>
        </>
    } 
    if(status.toLowerCase() == "true" && true_status.toLowerCase() == "false") {
        return <>
          <Label
            variant="ghost"
            color={'error'}
          >
            {sentenceCase("unverified")}
          </Label>
        </>
    } 
    if(status.toLowerCase() == "false" && true_status.toLowerCase() == "true") {
        return <>
          <Label
            variant="ghost"
            color={'warning'}
          >
            {sentenceCase("pending")}
          </Label>
        </>
    } 
    if(status.toLowerCase() == "false" && true_status.toLowerCase() == "false") {
        return <>
          <Label
            variant="ghost"
            color={'error'}
          >
            {sentenceCase("unverified")}
          </Label>
        </>
    }
  }

  const renderUserDetail = (user) => {
    if(!_.isEmpty(user)) {
        return (
          <TableRow
              hover
              key={user.id}
          >
            <TableCell align="left" style={{padding:"12px"}}>{user.id}</TableCell>
            <TableCell align="left" style={{padding:"12px"}}>{user.name}</TableCell>
            <TableCell align="left" style={{padding:"12px"}}>{user.email}</TableCell>
            <TableCell alig n="left" style={{padding:"12px"}}>{showStreet(user)}</TableCell>
            <TableCell align="left" style={{padding:"12px"}}>{showSuburb(user)}</TableCell>
            <TableCell align="left" style={{padding:"12px"}}>{showState(user)}</TableCell>
            <TableCell align="left" style={{padding:"12px"}}>{showPostCode(user)}</TableCell>
            <TableCell align="left" style={{padding:"12px"}}>{showQuarantineDays(user)}</TableCell>
          </TableRow>
        );
    }
  }

  const renderHistoryList = () => {
    if(!_.isEmpty(filteredUsers)) {
      return filteredUsers
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((user) => {
        const isItemSelected = selected.indexOf(user.id) !== -1;
        return (
          <TableRow
            hover
            key={user.id}
            tabIndex={-1}
            role="checkbox"
            selected={isItemSelected}
            aria-checked={isItemSelected}
          >
            <TableCell align="left" style={{padding:"10px"}}>{user.id}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{user.name}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{user.email}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{showStreet(user)}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{showSuburb(user)}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{showState(user)}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{showPostCode(user)}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{showQuarantineDays(user)+"/"+user.quarantine_days}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{renderStatus(user)}</TableCell>
            <TableCell align="left" style={{padding:"10px"}}>{renderDesc(user)}</TableCell>
          </TableRow>
        );
      })
    } 
  }

  const filteredUsers = applyFilter(props.userDetails);

  return (
    <Page title="UserDetails">
      <Container maxWidth="100%">
        <Box sx={{marginY:"15px"}}>
          <Typography>
            <b>User Details</b>
          </Typography>
        </Box>
        <Card sx={{width:"100%"}}>
          <TableContainer sx={{ minWidth: 800}}>
            <Table>
                <UserListHead
                    headLabel={TABLE_HEAD}
                />
                <TableBody>
                  {renderUserDetail(props.user)}
                </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Box sx={{marginY:"15px"}}>
          <Typography>
            <b>Validations History</b>
          </Typography>
        </Box>
        {!_.isEmpty(filteredUsers) ? (<Card sx={{width:"100%", marginBottom:"15px"}}>
          <TableContainer sx={{ minWidth: 800}}>
            <Table>
              <UserListHead
                headLabel={SEC_TABLE_HEAD}
                rowCount={props.userDetails.length}
              />
              <TableBody>
                  {renderHistoryList()}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={props.userDetails.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>) : <Card sx={{width:"100%", marginBottom:"15px"}}>No History Found</Card> }
      </Container>
    </Page>
  )
}

const mapStateToProps = state => {
    return {
      userDetails:Object.values(state.userDetails),
      users:Object.values(state.users),
    }
}

export default connect(mapStateToProps, { fetchUserDetails, resetState })(UserDetails);